<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('gameRulesParity.h1')}}</h4>
    <dl>
      <dt>{{$t('gameRulesParity.dt3')}}</dt>
        <dd>{{$t('gameRulesParity.dd3_1')}}</dd>
        <dd>{{$t('gameRulesParity.dd3_2')}}</dd>
        <dd>{{$t('gameRulesParity.dd3_3')}}</dd>
        <dd><img :src="require('../assets/images/' + imgUrl + 'bg155.jpg')" /></dd>
        <dd>{{$t('gameRulesParity.dd3_4')}}</dd>
        
      <dt>{{$t('gameRulesParity.dt1')}}</dt>
        <dd>{{$t('gameRulesParity.dd1_1')}}</dd>
        <dd>{{$t('gameRulesParity.dd1_2')}}</dd>
        <dd style="color:#077942">{{$t('gameRulesParity.dd1_3')}}</dd>
        <dd>{{$t('gameRulesParity.dd1_4')}}</dd>
        <dd style="color:#be3816">{{$t('gameRulesParity.dd1_5')}}</dd>
        <dd>{{$t('gameRulesParity.dd1_6')}}</dd>
        <dd style="color:#4844b4">{{$t('gameRulesParity.dd1_7')}}</dd>
        <dd>{{$t('gameRulesParity.dd1_8')}}</dd>
        <dd>{{$t('gameRulesParity.dd1_9')}}</dd>
        <dd>{{$t('gameRulesParity.dd1_10')}}</dd>

      <dt>{{$t('gameRulesParity.dt2')}}</dt>
        <dd>{{$t('gameRulesParity.dd2_1')}}</dd>
        <dd>{{$t('gameRulesParity.dd2_2')}}</dd>
        <dd style="color:#077942">{{$t('gameRulesParity.dd2_3')}}</dd>
        <dd>{{$t('gameRulesParity.dd2_4')}}</dd>
        <dd style="color:#be3816">{{$t('gameRulesParity.dd2_5')}}</dd>
        <dd>{{$t('gameRulesParity.dd2_6')}}</dd>
        <dd style="color:#4844b4">{{$t('gameRulesParity.dd2_7')}}</dd>
        <dd>{{$t('gameRulesParity.dd2_8')}}</dd>
        <dd>{{$t('gameRulesParity.dd2_9')}}</dd>
        <dd>{{$t('gameRulesParity.dd2_10')}}</dd>

      
    </dl>

    

  </div> 
</template>

<script>
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'GameRulesParity',
  props: {},
  data() {
    return {
      imgUrl: '',
      country: '',
    };
  },
  methods: {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.country = getCountry()
  },
  setup() {}
};
</script>

<style scoped>
.popclass-pp {
  padding: 15px 5px 10px 15px !important;
  border-radius: 5px;
  width: 70%;
}
.popclass-pp h4 {
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 16px;
  margin: 0 !important;
  border-bottom: 1px solid #eee;
}
.popclass-pp .txbd {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.play-rule {
  padding: 20px;
}

.play-rule h4 {
  margin: 5px 0;
  font-size: 17px;
}
.nut-popup h4.h4 {
  margin: 20px 10px;
}
.play-rule dl {
  position: absolute;
  overflow-y: scroll;
  top: 50px;
  bottom: 20px;
}

.play-rule dt {
  margin: 20px 0; padding-left: 10px; height: 22px; line-height: 22px;
  font-size: 17px; font-weight: 500; border-left:4px solid #0c3901 ;
}

.play-rule dd {
  margin:0 10px 5px 10px;
}
.play-rule dd.img {
  margin:0 10px 5px 0px;
}
.play-rule dd img{
  width: 100%;
}
.play-rule dd strong{ color: #0c3901;}
.play-rule .nut-button{ margin-right: 20px; width: auto !important;}
</style>
