<template>
  <nut-input
    :label="label"
    :type="type"
    :placeholder="placeholder"
    :rule="rule"
    :value="value"
    :max-length="maxLength"
    :style="style"
    :autocomplete="autocomplete"
    v-model="content"
  />
</template>
<script>
import { getCountry} from "@/assets/js/environment";
export default {
  data(){
    return{
      content:'',
      country:''
    }
  },
  props: ["label", "type", "placeholder",  "rule", "maxLength", "value", "style", "autocomplete"],
  methods: {
    handlerulg(){
      const rue = new RegExp(this.rule)
      //正则判断
      if(rue.test(this.content)){
        if(this.placeholder === this.$t('userLogin.mobileNumberPlaceholder') && this.country === 'ng'){
          if(this.content.substring(0,1) === 0 || this.content.substring(0,1) === "0"){
            let v = this.content.substring(1,this.content.length)
            this.content = v
          }
        }
        this.$emit('inputChange',this.content)
      }
    }
  },
  created() {
    this.country = getCountry()
  },
  mounted() {
    if(this.value){
      this.content = this.value
    }
  },
  watch:{
    content(){
      this.handlerulg()
    }
  },
};
</script>

<style>
    .nut-input-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        }
    .w100 .input-text {
        width: 100% !important;
    }
</style>
