// EN
module.exports = {
  // 枚举
  filtersText:{
    wait_audit: 'Wait Audit',
    audit_accept: 'Audit Accept',
    audit_reject: 'Audit Reject',
    processing: 'Processing',
    success: 'Success',
    failed: 'Failed',
  },
  // 通用
  global:{
    language: 'Language',
    recharge: 'जमा', //底部主导航
    invite: 'आमंत्रित करना',     //底部主导航
    reward: 'कार्य',     //底部主导航
    my: 'मेरे',             //底部主导航
    loading: "लोड हो रहा है...",                                    //加载中提示 1
    okText: "ठीक है",                                             //ok按钮文字 1
    cancelText: "रद्द करना",                                     //取消按钮文字 1 
    safeDays1: 'के लिए सकुशल संचालन कर रहा है',               //安全运营天数： safeDays1 +  ??? + safeDays2
    safeDays2: 'सुरक्षित दिन',
    dataListInfo :'केवल 3 महीने के अंदर का डेटा दिखाएं!',          //只展示3个月数据  2  Only show the Last 3 Month Data
    noMore:'अब और नहीं!',                                        //没有更多数据了
    noData:'कोई डेटा नहीं!',                                        //没有更多数据了
    inputRequired:'कृप्या आवश्यक फ़ील्ड भरें!',      //请填写必填项
    errInfoRequired:'यह आवश्यक है',                       //必填项错误提示
    mobileError: 'गलत मोबाइल नंबर प्रारूप!',               //电话号码错误提示
    passwordsError: 'अलग पासवर्ड दो बार!',            //两次密码不一致
    agreeError: 'कृपया पंजीकरण समझौते से सहमत हों', //请同意注册协议
    successfully_OPT: 'सफलतापूर्वक भेज दिया',
    successfully: 'सफलतापूर्वक भेज दिया',                        //短信验证码发送成功
    errorfully: 'गलत मोबाइल नंबर!',                       //短信验证码发送失败
    networkError:'नेटवर्क विसंगति!',                          //网络错误
    eMail:'प्रतिक्रिया ईमेल: ',                //提现底部提示1
    WithdrawalPartners:'भुगतान/निकासी भागीदारों नीचे.',  //提现底部提示2
    submittedSuccess:'सफलतापूर्वक सबमिट करें!',               //提交成功
    confirm:'पुष्टि करें',                                        //确定按钮
    submit:'प्रस्तुत करना',                                          //提交按钮
    save:'बचाना',                                              //保存按钮
    view:'दृश्य',                                              //查看按钮
    downloadApp: 'ऐप डाउनलोड करें',                              //下载app
    addDesktop: 'डेस्कटॉप में जोड़ें',                             //添加到桌面
    addDesktopTitle: 'आपकी सुविधा के लिए, हमारे पेज को डेस्कटॉप पर जोड़ने की अनुशंसा की जाती है',  //为了你更方便的使用，建议把我们添加到桌面
    addDesktopText1a: '1、क्लिक',               //1、点击 xx 图标   (第一步)
    addDesktopText1b: 'आइकन',                  //1、点击 xx 图标
    addDesktopText2: '2、डेस्कटॉप में जोड़ें चुनें" ',   //  (第二步)选择 "添加到主屏幕"
    rechargeBtnText:'जमा',  //充值按钮
    withdrawalBtnText:'निकासी',  //提现按钮
    inviteBtnText:'आमंत्रित करना',  //邀请按钮
    slogan1: '100% सुरक्षित कोई धोखा नहीं!',
    Today: 'आज',
    Week: '1 सप्ताह',
    Month1: '1 महीना',
    Month3: '3 महीना',
    Your_invitation_link_has_been_copied: 'आपका आमंत्रण लिंक कॉपी कर लिया गया है!',
    hotGame: 'गर्म खेल',
    newGame: 'नया खेल',
    top10: 'खिलाड़ी शीर्ष 10',
    NON_VIP_CAN_ONLY_INPUT: 'गैर वीआईपी केवल 10 इनपुट कर सकते हैं',
    INVALID_INPUT_VALUE: 'अमान्य इनपुट मान.',
    REQUEST_FAILED_PLEASE_TRY_LATER_OR_CONTACT_US: 'अनुरोध विफल रहा। कृपया बाद में प्रयास करें या हमसे संपर्क करें।',
    SERVER_ERROR: 'सर्वर त्रुटि। कृपया बाद में प्रयास करें या हमसे संपर्क करें.',
    SIGN_UP_BONUS_1: 'Sign Up To Get ', //Sign Up To Get ** Bonus >>
    SIGN_UP_BONUS_2: ' Bonus >>',
    SIGN_UP_BONUS_3: 'Register to get a discount coupon ',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    
    RECEBER_CUPOM_DE_DESCONTO_DE: 'Receive discount coupon ',
    OBTER_CUPOM: 'Get coupon',
    DISCOUNT_COUPON: 'Discount coupon',
    VALID: 'Valid',
    COUPON: 'Coupon',
    EXPIRED: 'Expired',
    USAR: 'Use',
    FUN_MODE: 'Fun Mode',
    RECIBO: 'Receipt',
    PAY: 'Pay',
    FUN_MODE_i: 'Fun mode amount is virtual amount, choose real mode to start real game experience',

    VIP_BONUS_1: 'यह बोनस केवल VIP',
    VIP_BONUS_2: '+ उपयोगकर्ताओं के लिए उपलब्ध है, इसे प्राप्त करने के लिए कृपया अपने VIP स्तर को अपग्रेड करें।',
    DEMO_END_TEXT: 'आज अनुभव करने के आपके अवसर का उपयोग किया गया है, वास्तविक जीत शुरू करने के लिए कृपया वास्तविक मोड पर जाएं।',
    TO_REAL_MODE: 'To real mode',
    Prediction_Channel: 'Prediction Channel',
    Prediction_Channel_Text: 'The prediction channel is only open to VIP1+ users, please upgrade your VIP level.',
    Got_It: 'Got It',
    Upgrade: 'Upgrade',

    Recharge_Dialog_Title: 'संतुलन पर्याप्त नहीं',
    Recharge_Dialog_Text: 'गेम का अनुभव लेने के लिए आप रिचार्ज कर सकते हैं या फन मोड में प्रवेश कर सकते हैं',
    Recharge_Dialog_Btn: 'खेल',

    Received:'Received',
    Total:'Total',
    Lucky_Lifafa:'Lucky Lifafa',
    Easy_share_Earn_Rupees:'Easy share, Earn Rupees',
    Remaining_today: 'Remaining today',
    pieces:'pieces',
    Play_now:'Play now',

    DOWNLOAD_APP: 'Download APP',
    DOWNLOAD_APP_TEXT:'For a better game experience, get rewards faster and get valuable information',
    DOWNLOAD_APP_TEXT2: 'Download the app now to claim ',
    DOWNLOAD_APP_TEXT3: 'bonus!',
    DOWNLOAD_APP_TEXT4: 'Please go to APP to receive reward!',
    DOWNLOAD_APP_BTN: 'Download',
    DOWNLOAD_APP_CANCEL: 'Cancel',
    
    Through_Email1: 'If there is any problem, please contact us through Email:support',
    Through_Email2: 'inwin99.com, working hours: 7:00-20:00',

    downloadPopup_h3: 'New update available',
    downloadPopup_p: "Please download the latest app from Google Play.",
    downloadPopup_later: 'Update later'
  },
  // 飞机游戏页
  game:{
    h1: 'Crash', //क्रैश गेम
    tips: 'सलाह',
    gamePaused: 'खेल रोका गया', 
    paused: 'रोके गए',
    playTime: 'खेलने का समय', 
    gameWillStartingIn: 'में खेल शुरू होगा',
    youWin: 'आप जीतते हैं',
    receiveBonus: 'बोनस प्राप्त करें',
    nextGame: 'अगला दौर',  
    all: 'सब',
    start: 'शुरू',
    stop: 'विराम',
    autoStop: 'ऑटो स्टॉप',  
    myBet: 'मेरा आदेश',
    players: 'खिलाड़ियों',
    myHistory: 'मेरा आदेश',
    winRatio: 'विराम',   
    winAmount: 'जीत',
    betAmount: 'इनपुट',
    last20: 'केवल अंतिम 20 खिलाड़ी दिखाएं जो शामिल हुए!',
    stopPlayer: 'प्लेयर बंद करो',
    bet: 'इनपुट',
    status: 'स्थिती',
    ratio: 'विराम',
    feeAmount: 'समय',
    escape: 'बच निकलना',
    more: 'केवल आज ही ऑर्डर दिखाएं, अधिक देखने के लिए क्लिक करें',
    noHistory: 'कोई शर्त इतिहास नहीं',
    historData:'इतिहास खेल डेटा',
    notEnough: 'आपकी वॉलेट राशि पर्याप्त नहीं है',
    pleaseWithdraw: 'अपने पैसे की सुरक्षा के लिए, कृपया निकासी करें।',
    //pleaseWithdraw2:'जीत के लिए बधाई! अपने पैसे की सुरक्षा के लिए, कृपया निकासी करें.',
    pleaseWithdraw1:'बधाई हो!',
    pleaseWithdraw2:'आपने इससे अधिक बनाया ',
    // pleaseWithdraw_200:'₹200',
    pleaseWithdraw3:'खेलना जारी रखने से पहले,',
    pleaseWithdraw4:'कृपया वापस लें सुनिश्चित करने के लिए ',
    pleaseWithdraw5:'कि आप सफलतापूर्वक धन प्राप्त कर सकते हैं.',
    go: 'जाओ!',
    gotitBtn: 'समझ गया',
    info1: 'एक मित्र को आमंत्रित करें',  // You can invite to fill up to xxxx
    info2: 'के लिए बोनस शुरू करना पुनः प्राप्त करें ',  // You can invite to fill up to xxxx
    waitForTheNextRound: 'अगले दौर का इंतजार करें!',
    info3: 'VIP0 इनपुट राशि केवल हो सकती है ',
    DialogTitle: 'बड़ी जीत के लिए बधाई!',
    DialogContent1: 'के लिये VIP ',
    DialogContent2: ', आपका अधिकतम स्टॉप अनुपात है ',
    DialogContent3: 'x, आप सीमा को अनलॉक करने के लिए VIP का स्तर बढ़ा सकते हैं.',
  },
  // 红绿游戏页
  PARITY:{
    H1: 'PARITY', //समानता
    NAME1: 'Parkash',
    NAME2: 'Desai',
    NAME3: 'Lakshmi',
    PLEASE_WAIT: 'कृपया प्रतीक्षा करें...',
    ROUND: 'गोल',
    NEXT_ROUND_PREDICTION_STSRT_AT: 'अगले दौर की भविष्यवाणी शुरू करे',
    THIS_PREDICTION_STARTS_AT: 'यह भविष्यवाणी बजे से शुरू होती है',
    LIVE_PREDICTION: 'लाइव भविष्यवाणी',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_1: 'लाइव भविष्यवाणी अब भविष्यवाणी कक्ष में उपलब्ध है! ',  //1 LIVE Prediction is now available in Prediction room!
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_2: 'भविष्यवाणी ', // 2
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_3: 'कमरा!',       // 3
    MY_CHOICE: 'मेरी पसंद',
    COUNT_DOWN: 'काउंट डाउन',
    SELECT_TIME: 'समय चुनें',
    RESULT_TIME2: 'परिणाम की जाँच करना',
    PAUSED_TIME: 'रोके गए',
    MY_POINT: 'मेरी शर्त',
    VIP: 'Vip',
    JOIN_GREEN: 'ग्रीन से जुड़ें',
    JOIN_VIOLET: 'वायलेट से जुड़ें',
    JOIN_RED: 'रेड से जुड़ें',
    JOIN_NUMBER: 'ज्वाइन नंबर',
    RECORD: 'अभिलेख',
    CONTINUOUS: 'निरंतर',
    PROBABILITY: 'संभावना',
    NEXT_PAGE: 'अगला पृष्ठ',
    UPGRADE: 'उन्नत करना',
    EVERYONE_ORDER: "इनपुट",
    MY_ORDER: 'मेरा आदेश',
    BALANCE: 'संतुलन',
    FEE: 'शुल्क',
    JOIN: 'जोड़ना',
    CONTRACT_POINT: 'अनुबंध राशि',
    ALL: 'सब',
    NUMBER: 'संख्या',
    VIP1_INPUT_FREELY: 'VIP1 इनपुट स्वतंत्र रूप से',
    VIP0_ROOKIE_GUIDE: 'VIP0 रूकी गाइड',
    FOLLOW_ADVICE_AND_YOU_WIN_FINALLY: "सलाह का पालन करें और अंत में आप जीतेंगे!",
    INPUT: 'इनपुट',
    LOSE: 'विफल',
    WIN: 'जीत',
    SO_THIS_ROUND_SHOULD_INPUT: 'इस दौर में, शर्त लगानी चाहिए',
    CONFIRM: "'पुष्टि करें",
    TOTAL_PROFIT: 'कुल लाभ',
    PROFIT: 'फायदा',
    USER: 'उपयोगकर्ता',
    SELECT: 'चुनना',
    POINT: 'इनपुट',
    PERIOD: 'अवधि',
    RESULT: 'परिणाम',
    AMOUNT: 'जीत',
    RESULT_TIME:'समय',
    BTC_PRICE: 'BTC कीमत',
    SERIAL_NUM: 'सीरियल नंबर',
    NEW: 'नया',
    SUGGEST: 'सुझाव देना',
    PREDICT: 'भविष्यवाणी करना',
    SERIAL: 'धारावाहिक',
    PROFIT_WIN_INPUT :'लाभ (जीत-इनपुट)',
    RESULTS_ORIGIN: 'परिणाम उत्पत्ति',
    PREDICTION_GAMEPLAY: 'भविष्यवाणी गेमप्ले',
    INVESTMENT_SKILLS: 'निवेश कौशल',
    ANALYST_INTRODUCTION: 'विश्लेषक परिचय',
    RESULTS_ORIGIN_TEXT_1: 'Results come from the last digit of the composite index of Coinbase Top 10 digital currencies.',
    PREDICTION_GAMEPLAY_TEXT_1: 'मंच संदर्भ के लिए सहकारी विश्लेषकों द्वारा दिन में 7 बार, 7-15 राउंड प्रति भविष्यवाणी द्वारा गणना की गई भविष्यवाणी के परिणाम प्रदान करता है।',
    PREDICTION_GAMEPLAY_TEXT_2: 'भविष्यवाणी खुलने का समय:',
    PREDICTION_GAMEPLAY_TEXT_3: 'विश्लेषक भविष्यवाणियों के साथ संयुक्त कुशल निवेश रणनीति लंबी अवधि में जीतने की बाधाओं को बढ़ा सकती है',
    PREDICTION_GAMEPLAY_TEXT_4: '(भविष्यवाणी के परिणाम केवल संदर्भ के लिए हैं, सटीकता की गारंटी नहीं है)',
    INVESTMENT_SKILLS_TEXT_1: 'विनिंग रेट और निवेश रणनीति दोनों महत्वपूर्ण हैं',
    INVESTMENT_SKILLS_TEXT_2: '1.निवेश की रणनीति',
    INVESTMENT_SKILLS_TEXT_3: 'सरल निवेश रणनीति【डबल बेट】: यदि आप इस राउंड को हार जाते हैं, तो अगले राउंड के लिए, आपके द्वारा निवेश की गई राशि को तब तक दोगुना करें जब तक आप जीत नहीं जाते।',
    INVESTMENT_SKILLS_TEXT_4: 'उदाहरण के लिए',
    INVESTMENT_SKILLS_TEXT_5: '2.पालन ​​करें और विलोम',
    INVESTMENT_SKILLS_TEXT_6: 'निरंतर का निरीक्षण करना सीखें, जब एक ही परिणाम एक पंक्ति में दिखाई देता है, तो विपरीत परिणाम चुनें, और जीतने की दर अक्सर अधिक होती है.',
    INVESTMENT_SKILLS_TEXT_7: 'आधिकारिक टेलीग्राम चैनल पर अधिक टिप्स >>',
    INITIAL_BALANCE: 'प्रारंभिक संतुलन',
    INVESTMENT_PLAN: 'निवेश योजना',
    TOTAL_ASSETS: 'कुल संपत्ति',
    ANALYST_INTRODUCTION_1: 'उत्कृष्ट गणितीय प्रतिभा के साथ एमआईटी से स्नातक, गणितीय सिद्धांत संभाव्यता विधियों के व्यापारिक अभ्यास पर ध्यान केंद्रित करते हुए, वॉल स्ट्रीट डेटा विश्लेषण के क्षेत्र में 8 से अधिक वर्षों तक काम किया',
    ANALYST_INTRODUCTION_2: 'ब्लॉकचैन निवेश अनुभव के 5 साल से अधिक, मूल्य विश्लेषण में अच्छा, इलिनोइस इंस्टीट्यूट ऑफ टेक्नोलॉजी से वित्तीय गणित के मास्टर, माउंट गोक्स व्यापार विभाग में काम किया',
    ANALYST_INTRODUCTION_3: 'टोरंटो विश्वविद्यालय से वित्तीय अर्थशास्त्र में स्नातक, एक मजबूत डिजिटल संवेदनशीलता है, और 28 दिनों के भीतर व्यापार में 51.1 गुना वृद्धि हासिल की है',
    CONGRSTS_XXX_WIN_XXX_1: 'बधाई ',   // Congrats 900***0000 Win 8888
    CONGRSTS_XXX_WIN_XXX_2: ' जीत ',
    GAME_WILL_COMING_SOON: 'खेल जल्द ही आ जाएगा!',
    ROOM_NOT_EXISTS: 'कमरा मौजूद नहीं है!',
    PLEASE_CONFIRM_YOUR_INPUT: 'कृपया अपनी शर्त की पुष्टि करें!',
    AS_A_ROOKIE_YOU_CAN_ONLY_INPUT: 'कृपया अपनी शर्त की पुष्टि करें ',
    ONLY_FOR_VIP1: 'केवल के लिए VIP1+',
    ONLY_FOR_VIP2: 'केवल के लिए VIP2+',
    CONNECTING: 'कनेक्ट',
    OOPS_PLEASE_CHECK_YOUR_NETWORK: 'उफ़! कृपया अपना नेटवर्क जांचें',
    TRY_AGAIN:'पुनः प्रयास करें',
    BTC_PRICE_BY_BINANCE: 'BTC बायनेन्स द्वारा कीमत',
  },
  //转盘
  WHEEL:{
    H1: 'WHEEL',
    HISTORY: 'इतिहास',
    BET_TIME: 'समय चुनें',
    PLAYING:'Playing',
    PLEASE_WAIT: 'Please wait',
    CLICK_TO_BET: 'Click to choose',
    VIP_ONLY: 'VIP1+ केवल',
    RULE_H1: 'Wheel Rule',
    RULE_T1: '1.कैसे खेलने के लिए',
    RULE_T2: 'खिलाड़ी टर्नटेबल के रंग का चयन कर सकते हैं, पहले बेट राशि का चयन करें, फिर रंग का।.',
    RULE_T3: '2.जीत',
    RULE_T4: 'नीले और पीले रंग का ऑड्स 2X है, बेट 100, जीत 200।',
    RULE_T5: 'लाल रंग के ऑड्स 9X हैं, बेट 100, जीत 900।',
  },
  // 挖雷游戏页
  MINE:{
    H1: 'MINE',
    ROW:'पंक्ति',
    START: 'शुरू',
    BONUS: 'बक्शीश',
    NEXT: 'अगला',
    STOP: 'विराम',
    POINT: 'इनपुट',
    SELECT: 'चुनना',
    WIN_AMOUNT: 'जीत राशि',
    ORDER_NUM: 'क्रम संख्या',
    STOP_AND_GET_BONUS: 'बंद करो और बोनस प्राप्त करें!',
    CHOOSE_GAME_AMOUNT: 'खेल राशि चुनें',
  },
  // 登录页
  userLogin: {
    signIn: 'साइन इन करें',                       //登录标题
    smsSignIn: 'SMS लॉग इन करें',                  //SMS登录标题
    mobileNumber: 'मोबाइल नंबर',           //手机输入框标题
    mobileNumberPlaceholder:'मोबाइल नंबर', //手机输入框默认提示
    password: 'पासवर्ड',                    //密码输入框标题
    passwordPlaceholder: '6~32 पासवर्ड',      //密码输入框默认提示
    login: 'लॉग इन करें',                          //确认登录 按钮
    loginSuccess: 'लॉगिन की सफलता!',          //登录成功 文字提示
    forgotPassword: 'पासवर्ड भूल गया?',      //忘记密码 链接
    register: 'पंजीकरण करवाना',                    //注册连接 链接
    SMSLogin: 'SMS सत्यापन लॉगिन',      //短信登录 链接
    passwordLogin: 'पासवर्ड लॉगिन',          //密码登录 链接
    signIn_text:'Already registered? ',
    signIn_btn: 'Login'
  },
  // 设置密码页
  pesetPassword: {
    h1:'पासवर्ड रीसेट',              //页面标题
    old:'पुराना पासवर्ड',               //旧密码输入框    1 
    oldPlaceholder:'पुराना पासवर्ड',    //旧密码输入框 提示   1
    new:'नया पासवर्ड',               //新密码输入框   1
    newPlaceholder:'नया पासवर्ड',    //旧密码输入框 提示 1
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये',
    confirmPasswordPlaceholder: 'पासवर्ड की पुष्टि कीजिये',
    success: 'पासवर्ड सफलता सेट करें!', //设置成功提示  1
  },
  // 找回密码
  resetPassword:{
    h1:'पासवर्ड रीसेट',
    newPassword: 'पासवर्ड रीसेट',
    newPasswordPlaceholder: '6~32 लंबाई',
    confirmPassword:'पासवर्ड की पुष्टि कीजिये',
    confirmPasswordPlaceholder:'पासवर्ड की पुष्टि कीजिये',
    success: 'पासवर्ड सफलता सेट करें!'
  },
  // 用户注册
  userRegister:{
    h1:'पासवर्ड सफलता सेट करें',                                  //页面大标题
    registerTopText1: 'सरकार द्वारा प्रमाणित ऑनलाइन प्लेटफॉर्म। आपका खाता और धन बैंक द्वारा नियंत्रित किया जाता है। आप कभी भी निकासी कर सकते हैं',  //注册顶部宣传文案
    registerTopText2: 'नया रजिस्टर बोनस',         //注册顶部宣传文案 第2段： New register bonus xxx
    registerText3: 'बिजनेस ईमेल:',
    registerText4: 'बिजनेस ईमेल:',
    verificationCode: 'पुष्टि संख्या',          //验证码输入框标题
    verificationCodePlaceholder: '6 अंक',        //验证码输入框默认提示
    OTP: 'OTP',                                     //发送验证码 按钮
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये',            //确认密码输入框 标题
    confirmPasswordPlaceholder: 'पासवर्ड की पुष्टि कीजिये', //确认密码输入框默认提示
    recCode: 'सिफारिश कोड',                   //邀请码输入框
    recCodePlaceholder: 'नहीं आवश्यक',             //邀请码输入框 默认提示
    iAgree: 'मैं सहमत हूं',                              //同意注册协议 文案
    privacyPolicy: 'गोपनीयता नीति',                //同意注册协议 链接
    registerBtn: 'पंजीकरण करवाना',                        //确认注册 按钮
    success:'पंजीकरण सफल',                //注册成功 弹窗 文案
    signUpBonus:'रजिस्टर बोनस',                   //注册成功 弹窗 文案
    signUpCoupon:'Received discount coupon',          //收到优惠券
  },
  
  //我的主页
  home: {
    h1: 'मेरा घर', 
    mobile: 'गतिमान',         // 改成 phone
    vip: 'VIP',               //
    inviteCode: 'निमंत्रण कोड', //
    balance:'संतुलन',        //
    recharge:'जमा',      //
    withdrawal:'निकासी',  //
    vipDescription: 'VIP वीआईपी विवरण',
    link:'आधिकारिक चैनल',      // Official Telegram
    link_r: 'दैनिक बक्शीश',
    promotion: 'पदोन्नति',            //
    envelopesCenter:'लिफाफा केंद्र',//
    transactions:'लेनदेन',       //
    bankCard:'बैंक कार्ड',            //  ph: GCash
    resetPassword:'पासवर्ड रीसेट',    //
    cs:'शिकायतों & सुझाव',     //
    about:'के बारे में',                     //
    logout:'लॉग आउट',          //退出登陆
    vipTitle: 'VIP वीआईपी विशेषाधिकार विवरण',   //
    withdrawakQuoat: 'निकासी कोटा',      //
    level: 'वीआईपी स्तर',                       //
    totalRecharge: 'कुल रिचार्ज',          //
    profitRatio: 'लाभ अनुपात',              //
    withdrawalServices: 'निकासी सेवा',//
    vipRewards: 'वीआईपी पुरस्कार',                //
    dt: 'निकासी राशि का विवरण:',  //
    dd1: '1、कुल निकासी = कुल रिचार्ज x लाभ अनुपात;',  //
    dd2: 'VIP8 निकासी रिचार्ज प्रतिबंधों के अधीन नहीं हैं, इसलिए आप जितना कमाते हैं उतना निकाल सकते हैं.',  //
    gameAmount: 'खेल राशि प्लेटफॉर्म इन्वेस्ट प्रॉफिट और रिचार्ज से आती है।',
    agentAmount: 'एजेंट राशि एजेंट कमीशन प्रणाली से आती है'

  },
  // 设置头像
  setAvatar: {
    h1:'अवतार सेट करें',                  //页面大标题
    current:'वर्तमान अवतार',         //当前头像
    avatars:'चुनने के लिए अवतार:',//选择头像
    success:'अवतार सफलतापूर्वक बदल दिया गया!' //成功提示
  },
  //银行卡
  bankCard: {
    h1: 'बैंक कार्ड',
    noBankcCard: 'वर्तमान में कोई बैंक कार्ड बाध्य नहीं है!',
    addBankcCard: 'बैंक कार्ड जोड़ें',
    delete: 'हटाएं या नहीं?',
    editTitle: 'बैंक कार्ड संपादित करें',      //编辑银行卡
    addTitle: 'बैंक कार्ड जोड़ें',        //添加银行卡
    realName: 'वास्तविक नाम',            //输入框字段
    realNamePlaceholder: 'वास्तविक नाम(English)', //输入框字段 提示
    ifscCode: 'आईएफएससी कोड',            //输入框字段
    ifscCodePlaceholder: 'आईएफएससी कोड', //输入框字段 提示
    bankName: 'बैंक का नाम',            //输入框字段
    bankNamePlaceholder: 'बैंक का नाम', //输入框字段 提示
    bankAccount: 'बैंक खाता',      //输入框字段
    bankAccountPlaceholder: 'बैंक खाता',//输入框字段 提示
    upiVpa: 'UPI ID (Vpa)',                //输入框字段
    upiVpaPlaceholder: 'UPI ID (Vpa)',     //输入框字段 提示
    email: 'ईमेल',                   //输入框字段
    emailPlaceholder: 'ईमेल',        //输入框字段 提示
    success:'सफलता',                //成功提示
    addcard_text:"'कृपया सुनिश्चित करें कि जानकारी सही है, निकासी प्राप्त करने में सक्षम नहीं होने से बचें",
    GCash: 'GCash', 
    GCashPlaceholder: 'GCash गतिमान', 
  },
  //投诉 & 建议
   CS: {
    h1: 'शिकायत सुझाव',
    noData: 'कोई शिकायत और सुझाव नहीं!',
    addBtn: 'शिकायतें जोड़ें',
    waitReply: "'जवाब रुको",
    replied: 'उत्तर दिया',
    h2: 'शिकायत या सुझाव लिखें',
    type: 'टाइप',
    typePlaceholder: 'प्रकार चुनें',
    title: 'शीर्षक',
    titlePlaceholder:'कृपया शीर्षक दर्ज करें',
    description: 'विवरण',
    descriptionPlaceholder: 'कृपया विवरण दर्ज करें',
    servicTime: 'सेवा: 10:00~16:00, Mon~Fri',
    servicTime2: 'कृपया अपनी समस्या का स्क्रीनशॉट प्रदान करें, यूपीआई/यूटीआर नंबर और अपनी आईडी, हमारे ग्राहक सेवा ईमेल पर',
    recharge: 'जमा',
    withdraw: 'निकासी',
    invite: 'आमंत्रित करना',
    gameCrash: 'गेम Crash',
    others: 'अन्य',
  },
  // 消息
  envelopes: {
    h1:'लिफाफा केंद्र',  //页面大标题
    new: 'नया',             //新消息标识
  },
  // 充值页
  recharge: {
    h1:'जमा',               //页面大标题
    balance: 'संतुलन',          //标题
    amount: 'रकम',            //金额title
    amountPlaceholder: 'रकम', //金额输入框提示
    receipt: 'रसीद',          //手续费title
    receiptPlaceholder: 'रकम',//手续费输入框提示
    channel: 'चैनल',          //渠道title
    success: 'रिचार्ज सफल!',//充值成功
    failSelect: 'कृपया एक रिचार्ज चैनल चुनें!',         //请选择充值渠道！
    failEnter: 'कृपया सही मात्रा दर्ज करें!',           //请输入正确的金额
    pop:'नए पेज पर भुगतान पूरा करें, कृपया प्रतीक्षा करें',             //请在新页面完成支付
    description:'विवरण',
    tx5: '1.रिचार्ज के साथ कोई समस्या, कृपया हमसे संपर्क करें ',
    tx5_1: 'टेलीग्राम का समर्थन करें',
    tx5_2: ' ,काम के घंटे: 7:00-20:00',
    tx6: '2.रिचार्ज राशि आपके वीआईपी स्तर को बढ़ाएगी, और एक उच्च वीआईपी स्तर आपकी निकासी राशि, दैनिक बोनस आदि को बढ़ाएगा ',
    tx7: 'देखें',
    tx8: '“वीआईपी विवरण”',
    tx9: '3.इसे चोरी होने से बचाने के लिए अपना पासवर्ड प्रकट न करें। हमारे कर्मचारी आपका पासवर्ड नहीं पूछेंगे',
    levelUpVip: 'अभी अपग्रेड करें!',
    popTitle_1:'वीआईपी विवरण',
    popTitle_2:'वीआईपी विवरण',
    
  },
  // 充值列表页
  rechargeList: {
    h1:'रिचार्ज इतिहास',         //页面大标题
    giftAmount: 'उपहार राशि',     //列表字段
    applyAmount: 'राशि लागू करें',   //列表字段
  },
  // 提现页
  withdrawal: {
    h1:'निकासी',                       //页面大标题
    availableBalance:'उपलब्ध शेष राशि',  //可用余额
    agentAmount: 'एजेंट राशि',           //邀请金额
    gameAccount: 'गेम खाता',           //游戏金额
    transfer: 'स्थानांतरण करना',
    withdrawAmount: 'राशि वापस ले',     //提现金额 输入框标题
    handlingFee: 'संचालन शुल्क',           //手续费 输入框标题
    receiptAmount: 'रसीद राशि',       //收款金额 输入框标题
    bankCard: 'बैंक कार्ड',                 //银行卡选择 标题
    withdrawalBtn: 'निकासी',           //提交 按钮
    errInfoBankCard: 'निकासी के लिए कृपया एक बैंक कार्ड जोड़ें', //请先添加银行卡 提示信息
    errInfoRecharge: 'निकासी के लिए आपको और रिचार्ज करने की जरूरत है', //请先充值才能提现 提示信息
    errInfopPlayMore: 'सर/मैडम, आपकी बेट राशि बहुत कम है, कृपया खेलते रहें',    //请先玩更多才能提现 提示信息                
    description:'विवरण',
    tx4: '1.If there is any problem with the recharge, please contact us through ',
    tx4b: 'If there is any problem with the recharge, please contact us through ',
    tx5: '1.यदि निकासी में कोई समस्या है, तो कृपया हमसे संपर्क करें ',
    tx5b: 'यदि निकासी में कोई समस्या है, तो कृपया हमसे संपर्क करें ',
    tx_contact_us:'contact us',
    tx5_1: 'टेलीग्राम का समर्थन करें',
    tx5_2: ' , काम के घंटे: 7:00-20:00',
    tx6: '2.उच्च वीआईपी स्तर निकासी की सीमा बढ़ा सकता है',
    tx7: 'देखें',
    tx8: '“VIP विवरण',
    tx9: '3.मनी लॉन्ड्रिंग को रोकने के लिए, आपको निकासी से पहले पर्याप्त मात्रा में इनपुट प्राप्त करना होगा',
    tx10: 'बधाई हो',
    tx11: '🎉🎉🎉आपकी खाता सुरक्षा सत्यापित हो गई है🤝🤝🤝अब आप खेलना जारी रख सकते हैं!🥳🥳🥳',
    btntx: 'खेलें'
  },

  // 提现列表页
  withdrawalList: {
    h1:'निकासी इतिहास',            //页面大标题
    receiptAmount: 'रसीद राशि',    //列表字段
    applyAmount: 'राशि लागू करें',        //列表字段
    withdrawFee: 'निकासी शुल्क',        //列表字段
    pleaseCheckBank: 'कृपया पुष्टि करें कि आपकी बैंक जानकारी सही है और फिर से वापस लें',    //列表字段
  },

  // 金币转换
  transfer: {
    h1:'स्थानांतरण करना',
    transferAmount: 'स्थानांतरण राशि',
    btntx: "पुष्टि करें",
    success: 'स्थानांतरण सफलता',
    tx1: 'गेम राशि प्लेटफॉर्म निवेश लाभ और रिचार्ज से आती है। एजेंट राशि एजेंट कमीशन प्रणाली से आती है.',
    tx2: 'यदि आपको इसकी आवश्यकता है, तो एजेंट राशि को गेम खेलने के लिए स्वतंत्र रूप से गेम राशि में स्थानांतरित किया जा सकता है, गेम राशि को एजेंट राशि में स्थानांतरित नहीं किया जा सकता है, दोनों राशि निकाली जा सकती है.',
  },

  //钱包明细列表
  transactions:{
    h1:'लेनदेन',                   //页面大标题
    cashH1:'नकद लेनदेन',
  },
  //任务页
  taskReward:{
    h1: 'कार्य',                   //页面大标题
    day: 'दिन',                          //新手任务   xx day
    checkIn:'चेक इन',
    checkInLocked:'ताला',
    toTask: 'जाओ',                //去做任务按钮
    dailyQuest: 'दैनिक मिशन',  
    dailyQuestLocked: 'दैनिक मिशन (ताला)',
    dailyQuestCompleted: 'दैनिक मिशन (पूर्ण!)',
    dailyQuestFinished: 'दैनिक मिशन (समाप्त)',
    completeTask: 'जाओ',//完成任务
    receiveReward: 'पुरस्कार प्राप्त करें',       //领取奖励按钮
    received: 'प्राप्त हुआ',                //已经领取按钮
    completed: 'पूरा',              //按钮
    complete: 'पूरा',                //完成百分比    100% Complete
    checkIn7Text: 'चेक-इन लगातार 7 दिनों के लिए',      //签到进度标题  Check in for 7 consecutive days (x/x)
    vipCheckInText: 'अधिक बोनस पाने के लिए VIP को अपग्रेड करें',//签到进度副标题
    checkInText: 'पुरस्कार प्राप्त करने के लिए दैनिक चेक-इन',    //7天之后的 签到标题
    missionTarget: 'कार्य',     //任务列表标题
    getBn: 'इनाम मिला!',              //领取弹框 大标题
  },
  // 代理激励计划
  agentGrowth: {
    h1: 'एजेंट ग्रोथ',
    bn1: "एजेंट करोड़ नकद विकास योजना ",   //bn1 + xx + bn2 + xx + bn3!
    bn2: " स्स्तरों में विभाजित है, और प्रत्येक स्तर में उदार पुरस्कार हैं। सबसे ऊंचे लेवल को पूरा करें और तुरंत ",
    bn3: " का इनाम पाएं.",
    target:'लक्ष्य',
    h4_1: 'इनाम की शर्तें',
    h4_item1_1: 'आमंत्रित करना',
    h4_item1_2: 'वैध व्यक्ति (रजिस्टर और रिचार्ज), आप नकद प्राप्त कर सकते हैं',
    h4_2: 'अपग्रेड कौशल',
    h4_item2_1: 'You share the link and someone else register through the link, that person will be your subordinate. Once ',     
    h4_item2_2: ' subordinates become effective, you can get and withdraw ',   
    h4_item2_3: ' immediately.',
  },
  // 代理激励计划
  AGENT_SUPPORT_PLAN: {
    H1: 'एजेंट सहायता योजना',
    MY_SUPPORT_PLAN: 'मेरा समर्थन योजना',
    MY_SUPPORT_PLAN_LEVEL: 'मेरा समर्थन योजना स्तर',
    TODAY_EFFECIVE_USERS_AMOUNT: 'आज प्रभावी उपयोगकर्ता राशि',
    TODAY_POINTS_BONUS: 'आज बेट बोनस',
    YESTERDAY_SUPPORT_REWARD: 'आज समर्थन पुरस्कार',
    BTN_TEXT: 'प्राप्त करने के लिए ग्राहक सेवा से संपर्क करें >>',
    INFO_TEXT: 'यदि सिस्टम को किसी भी धोखाधड़ी का पता चलता है, तो बोनस और खातों को फ्रीज कर दिया जाएगा।',
    AGENT_REWAED_TOP: 'एजेंट इनाम शीर्ष 5',
    LEVEL: 'स्तर',
    LV: 'स्तर',
    PHONE: 'खाता',
    ORIGINAL_SYSTEM_REWAED: 'मूल प्रणाली इनाम',
    SUPPORT_REWARD: 'समर्थन इनाम',
    ONLY_TOP_5: 'केवल शीर्ष 5 डेटा दिखाएं!',
    DAILY_REQUIRE: 'दैनिक अनुरोध',
    REWARD: 'इनाम',
    EFFECIVE_USERS_AMOUNT: 'प्रभावी उपयोगकर्ता राशि',
    POINTS_BONUS: 'शर्त बोनस',
    TOTAL_SUPPORT_REWARD: 'कुल समर्थन इनाम ',
    CONSECUTIVE_DAYS_EXTRA_REWAED: 'लगातार 7 दिन अतिरिक्त इनाम',
    I_E_Q: '(प्रभावी मिशन को आमंत्रित करें) बोनस',
    //new
    Event_time:'Time',
    Invitee_personal_recharge_5000_1: 'Invitee personal recharge < 5000',
    Invitee_personal_recharge_5000_2: 'Invitee personal recharge ≥ 5000',
    Total: 'Total',
    Commission: 'Commission',
    Recharge_Amount: 'Recharge Amount',
    text1: "When an invitee's personal total recharge reaches 5k, his recharge  commission rebate ratio will change from 1% to  10%",
    text2: "Congratulations on successfully receiving commission rewards",
    text3: "The time to receive commission has not yet arrived",
    text4: "Event has ended!",
    received: "Received",
    Claim_Commission_after:'Claim Commission after ',
    Description:'Description',
    Receive_Reward:'Receive Reward',
    p1: "During the event, you will get commission rebate for every recharge of your invitees. The commission rebate ratio is 1% or 10%.",
    p2: "The 1% commission rebate ratio is applicable to invitees whose personal total recharge is less than 5k during the event. For example: 3 users recharge 2000 each, total 6000, Commission = 6000 x 1%",
    p3: "The 10% commission rebate ratio is applicable to invitees whose personal total recharge is more than 5k during the event. For example: 1 user recharge 5000 each, Commission = 5000 x 10%.",
    p4: "When an invitee's personal total recharge reaches 5k, his recharge  commission rebate ratio will change from 1% to  10%",
    p5: "After the end of each period, please receive the commission within 24 hours, and then it will automatically enter the next period."
  },	
  // 邀请
  invite: {
    h1: 'आमंत्रित करना',
    agentAmount: "एजेंट राशि",
    withdraw:'निकासी',
    totalPeople: 'कुल लोग',
    effectiveNum: 'प्रभावी संख्या',
    h3: 'करोड़ एजेंट विकास योजना',
    contribution: 'योगदान बोनस',
    more: 'और देखने के लिए स्वाइप करें',                                           //更多提示
    affinity: 'श्रेणी',                                                //table 字段
    inviteCount: 'आमंत्रण संख्या',                                         //table 字段
    effectiveCount: 'प्रभावी गणना',                                   //table 字段
    effectiveBonus: 'प्रभावी बोनस',                                   //table 字段
    inviteCommissionAmount: 'बोनस आमंत्रित करें',                  //table 字段
    subBetCommissionRatio:'अंक बोनस',                    //table 字段
    subBetCommissionAmount:'अंक बोनस',                  //table 字段
    subFirstRechargeCommissionRatio:'उप प्रथम रिचार्ज कमीशन अनुपात',//table 字段
    subFirstRechargeCommissionAmount:"उप प्रथम रिचार्ज कमीशन राशि",//table 字段
    totalCommissionAmount:'कुल बोनस',                     //table 字段
    Grade: 'श्रेणी',
    Bonuss: 'बक्शीश',
    rules: 'नियम',                 //tab标题1
    bonus: 'बक्शीश',                 //tab标题2
    myCode: 'मेरा प्रचार कोड',    //tab 右侧链接标题
    you: 'तुम',
    youTitle: '4-स्तरीय एजेंसी सिस्टम, प्रत्येक स्तर कमीशन लाता है!',
    level: 'श्रेणी',              // 等级title
    label1: 'बक्शीश',     // 第1个字段名称

    rulesTitle1: 'सबसे सरल',         // 规则1标题
    rulesText11: 'प्रत्येक पंजीकृत उपयोगकर्ता के लिए ₹1~₹99 का इनाम, तुरंत आय प्राप्त करें!', // 规则1内容
    rulesText12: 'अगर आप 100 उपयोगकर्ताओं को आमंत्रित करते हैं, तो कम से कम ₹400 और संभवतः ₹9900 तक कमाएं',

    rulesTitle2: 'सबसे लंबा',         // 规则2标题
    rulesText21: 'चार-स्तरीय आमंत्रित, प्रत्येक उपयोगकर्ता कमीशन उत्पन्न करेगा', // 规则2内容
    rulesText22: 'यदि प्रत्येक व्यक्ति 10 लोगों को आमंत्रित करता है, तो प्रत्येक व्यक्ति को प्रति दिन केवल ₹100 की शर्त लगानी होगी, 30 दिनों के बाद, चार-स्तरीय एजेंसी प्रणाली के माध्यम से, आप ₹35,000 से अधिक कमाएंगे!', // 规则2内容

    rulesTitle3: 'सबसे प्रभावी',         // 规则3标题
    rulesText31: 'आधिकारिक टेलीग्राम चैनल में बोनस लिंक पर क्लिक करें और शेयर करें, अपनी उंगलियों पर पैसे कमाएं', // 规则3内容
    rulesText32: 'हमारा एजेंट बिंदिया एक स्ट्रीट वेंडर है, वह लिंक साझा करके हर दिन 100 नए उपयोगकर्ताओं को साइन अप करने के लिए आमंत्रित करता है, वह शायद हर दिन ₹1500 कमाता है! यदि वह कर सकता है, तो आप भी कर सकते हैं!',

    rulesTitle4: 'रिकॉल क्या है?',
    rulesText41: 'यदि कोई उपयोगकर्ता 21 दिनों तक नहीं आया है, और आपके आमंत्रण लिंक या फ़ॉरवर्ड लिफ़ाफ़ा लिंक के माध्यम से फिर से आता है, तो उसे आपका नया आमंत्रित उपयोगकर्ता माना जाएगा। प्रचार करते रहें और आपको नए उपयोगकर्ता और कमीशन मिलते रहेंगे.',

    h4: 'मेरा प्रचार कोड',
    h4Title: '2024 में सबसे लोकप्रिय ऑनलाइन कमाई का मंच। आसानी से 3,000 ~ 20,000 रुपये कमाएं',
    btn: 'प्रतिरूप जोड़ना',
    to: 'या साझा करें',
    shareMakeMoneya: 'शेयर करें पैसा कमाएं',
    info1: 'आपके द्वारा आमंत्रित प्रत्येक उपयोगकर्ता आपको अधिकतम रु. 99 का बोनस देगा, और जिन्हें आप आमंत्रित करेंगे उन्हें मिलेगा ',
    info2: ' बक्शीश.',

    //ShareNetwork
    url: '',        //分享链接
    title: ' सबसे सुरक्षित कमाई मंच ',      //分享标题
    description: ' 2024 में भारत में पैसा बनाने के त्वरित और आसान तरीके शुरुआती बोनस🚀3m🚀100X जीत🚀 ₹10 मिलियन शेयर इनाम🚀100% सुरक्षित🚀 में निकासी🚀 जितनी जल्दी आप जुड़ेंगे, उतना ही अधिक कमाएंगे!',//分享描述
    quote: 'आप जितनी जल्दी ज्वाइन करेंगे उतनी ही ज्यादा कमाई करेंगे!',  
    hashtags: 'game',  //分享关键词
    twitterUser: '',     //作者
    cashHistory:'नकद इतिहास',
    infoToast: 'Invited users must meet the effective number standard required by the system, otherwise they will not be counted. Invite more effective users, and the statistics will resume.',

    Congratulations:'Congratulations',
    NewAgencyIncome:'Recent Agency Income',
    NewInvites:'Recent Invites',
    NewEffective:'Recent Effective',
    EarnMoreNow:'Earn More Now',
    Last30Days: 'The data comes from the last 30 days',
  },
  //我的历史下注
  myPlayList: {
    h1: 'मेरा आदेश',      //页面大标题
    bet: 'इनपुट: ',
    win: 'जीत: ',
    crash: 'टकरा जाना',
    ratio: 'विराम',
    originWinAmount: 'जीत: ',
    feeAmount: 'शुल्क: ',
    gameNum: '',
    time:'समय'
  },
  //领取红包页面
  receiveBonus:{
    h1: 'बोनस प्राप्त करें',              //页面大标题
    name: 'आपके दोस्त',              // xxxx  （没有手机号的时候会显示这个字段）
    h2: 'आपको बोनस भेजता है !',        // xxxx 给你发来了红包！
    h3: 'भेज दिया है',
    btn: 'प्राप्त करना',               //按钮
    btn2: 'नवीनतम बोनस प्राप्त करें', //按钮
    success: 'सफलता प्राप्त करें!',      //成功提示
    user: 'उपयोगकर्ता',                     // 用户 xxxx 领取了红包  前
    receives: 'पुरस्कार प्राप्त करता है !',// 用户 xxxx 领取了红包  后
    // errText: 'नवीनतम शेयर बोनस प्राप्त करने के लिए कृपया बटन पर क्लिक करें!',
    errText: '',
  },
  //分享红包页面
  shareBonus:{
    h1: 'शेयर बोनस',                         //页面大标题
    h2: 'बोनस सफलतापूर्वक प्राप्त करें!', //页面标题
    h3: 'दूसरों को शेयर करें आपको 100% रिटर्न मिलेगा!',              //副标题
    btn: 'पैसे कमाने के लिए शेयर करें',                 //按钮
    copied: 'आपका बोनस लिंक कॉपी किया गया!',//复制地址之后提示文字
  },
  //小提示
  tips:{
    tips1: 'न्यूनतम पॉइंट ₹10, अधिकतम पॉइंट ₹10000',
    tips2: 'प्रतिदिन कार्यों को पूरा करके धन पुरस्कार प्राप्त करें!',
    tips3: 'रॉकेट X100 तक उड़ सकते हैं！',
    tips4: 'पैटर्न ढूँढना जीत की कुंजी है！',
    tips5: 'कुमार 85.77 बार सफलतापूर्वक जीतने वाले पहले स्काईडाइवर हैं, उन्हें श्रद्धांजलि देते हैं!',
    tips6: 'कम पर लगातार दुर्घटना, अगले दौर में ज्यादा ऊंची उड़ान भरने की संभावना बढ़ जाएगी！'
             
  },
  //vip说明
  VIP_PRIVILEGE: {
    VIP_PRIVILEGE: 'VIP Privilege',
    WITHDRAWAL_DESCRIPTION: 'Withdrawal Description',
    LEVEL: 'Level',
    TOTAL_RECHARGE: 'Total Recharge',
    WITHDRAWAL_QUOTA: 'Withdrawal Quota',
    REWARDS: 'Rewards',
    DAILY_BONUS: 'Daily Bonus',
    EXTRA: 'Extra',
    WITHDRAWAL: 'Withdrawal',
    STOP_LIMIT: 'Stop Limit',
    INPUT_LIMIT: 'Input Limit',
    GAMEPLAY: 'Gameplay',
    DATA_ANALYSIS: 'Data Analysis',
    ROOM_LIMIT:'Room Limit',
    MAX: 'Max',
    RECORD: 'Record',
    CONTINUOUS: 'Continuous',
    PROBABILITY: 'Probability',
    TEXT1: 'Withdrawable amount(Lv1-Lv3)=Balance - Starting bonus(',
    TEXT2: ') Upgrade to VIP4 to withdraw freely!the withdrawal limit up to 12000!',
    TEXT3: 'Upgrade to the corresponding VIP level to complete this withdrawal',
  },
  //游戏规则 飞机
  gameRules:{
    h1: 'प्ले नियम',
    dt1: 'कैसे खेलें ?',
    dd1_1: 'सबसे पहले, चुनें कि आप कितना ऑर्डर देना चाहते हैं। अपना ऑर्डर देने के लिए "START" पर क्लिक करें और गुणक को 1.00x से ऊपर की ओर बढ़ते देखें! ',
    dd1_2: 'आप अपने बिंदु को वर्तमान गुणक से गुणा करने के लिए किसी भी समय "रोकें" पर क्लिक कर सकते हैं। लेकिन सावधान रहें: खेल किसी भी समय समाप्त हो सकता है, और यदि आप इसे तब तक रोकते हैं, तो आपको कुछ नहीं मिलेगा! ',
    dd1_3: 'आपको अगला गेम शुरू होने का इंतजार करना होगा',
    dt2: 'राशि की गणना कैसे की जाती है?',
    dd2_1: 'यदि आपके ऑर्डर की राशि 100 है, तो एस्केप मल्टीपल 10.0x है, 3 रुपये सेवा शुल्क काटने के बाद, आपकी अनुबंध राशि 97 रुपये है:',
    dd2_2: '10.0X: (97*10) 970 रुपए मिलेंगे.',
    dt3: 'गुणक कितना ऊंचा जा सकता है?',
    dd3: '100 बार तक',
    dt4: 'ऑटो स्टॉप क्या है ?',
    dd4: 'यदि आप ऑटो स्टॉप का चयन करते हैं, तो जब मल्टीपल चयनित मल्टीपल तक पहुंचता है, तो यह ऑटो बंद हो जाएगा और पुरस्कार प्राप्त करेगा',
    dt5: 'गुणक अलग-अलग रंगों में क्यों होते हैं? ',
    btn: 'समझ गया',
  },
  //游戏规则 
  gameRulesParity:{
    h1: 'प्ले नियम',
    dt1: 'तेज़',
    dd1_1: '30 सेकंड का राउंड, ऑर्डर देने के लिए 25 सेकंड, परिणाम घोषित करने के लिए 5 सेकंड, बिना रुके 24 घंटे, एक दिन में कुल 2880 राउंड.',
    dd1_2: 'एक राउंड में 100 रुपये इनपुट करें',
    dd1_3: 'ग्रीन से जुड़ें:',
    dd1_4: 'यदि परिणाम 1,3,7,9 है, तो आपको 195 रुपये मिलेंगे, यदि परिणाम 5 है, तो आपको 142.5 रुपये मिलेंगे।.',
    dd1_5: 'रेड से जुड़ें:',
    dd1_6: 'परिणाम 2,4,6,8 है तो 195 रुपये मिलेंगे, परिणाम 0 है तो 142.5 रुपये मिलेंगे.',
    dd1_7: 'वायलेट में शामिल हों:',
    dd1_8: 'यदि परिणाम 0,5 है, तो आपको 427.5 रुपये मिलेंगे',
    dd1_9: 'नंबर चुना:',
    dd1_10: 'अगर रिजल्ट आपकी पसंद का नंबर है तो आपको 855 रुपए मिलेंगे',

    dt2: 'भविष्यवाणी',
    dd2_1: '90 सेकंड का एक राउंड, ऑर्डर देने के लिए 80 सेकंड, परिणाम घोषित करने के लिए 10 सेकंड, 24 घंटे नॉन-स्टॉप, एक दिन में कुल 960 राउंड।',
    dd2_2: 'इनपुट 100 रुपये एक चक्कर में',
    dd2_3: 'ग्रीन से जुड़ें:',
    dd2_4: 'यदि परिणाम 1,3,7,9 है, तो आपको 195 रुपये मिलेंगे, यदि परिणाम 5 है, तो आपको 142.5 रुपये मिलेंगे।',
    dd2_5: 'रेड से जुड़ें:',
    dd2_6: 'परिणाम 2,4,6,8 है तो 195 रुपये मिलेंगे, परिणाम 0 है तो 142.5 रुपये मिलेंगे',
    dd2_7: 'वायलेट में शामिल हों:',
    dd2_8: 'यदि परिणाम 0,5 है, तो आपको 427.5 रुपये मिलेंगे',
    dd2_9: 'नंबर चुना:',
    dd2_10: 'अगर रिजल्ट आपकी पसंद का नंबर है तो आपको 855 रुपए मिलेंगे.',

    dt3: 'सत्य ही वास्तविक मेला है, विश्वसनीय को पारदर्शिता की आवश्यकता होती है',
    dd3_1: 'प्रत्येक निवेश उपयोगकर्ता के हितों को सुनिश्चित करने के लिए, आईबीएएस और एमजीए प्रमाणीकरण मानकों का अनुपालन करने के लिए, समता संख्या परिणाम उत्पन्न करने के लिए निम्नलिखित निर्देश दिए गए हैं',
    dd3_2: '1. परिणाम स्रोत: उस समय बिनेंस बीटीसी / यूएसडीटी स्पॉट ट्रेडिंग जोड़ी के समापन मूल्य का अंतिम अंक लें जब समता समय समाप्त होता है',
    dd3_3: '2. डेटा सत्यापन: आप ऑर्डर पेज से ऐतिहासिक परिणामों का समय और संबंधित मूल्य देख सकते हैं, और बाइनेंस के दूसरे स्तर के ऐतिहासिक डेटा की तुलना कर सकते हैं',
    dd3_4: "नोट: बाइनेंस वर्तमान में दुनिया का सबसे बड़ा क्रिप्टो करेंसी एक्सचेंज है। बीटीसी/यूएसडीटी बिटकॉइन/टीथर के व्यापारिक मूल्य को संदर्भित करता है। $760k/दिन के ट्रेडिंग वॉल्यूम के साथ, कोई भी सेकंड में मूल्य के आंकड़ों में हेरफेर नहीं कर सकता है",
    btn: 'समझ गया',
  },
  gameRulesMine:{
    h1: 'मेरा नियम',
    dt1: 'खेलने के तीन तरीके: 2x2, 4x4, 6x6, प्रत्येक तरीके में अलग-अलग संख्या में बॉक्स और बोनस हैं',
    dt2: '4x4 को एक उदाहरण के रूप में लें, कुल 16 बक्से, जिनमें से एक खदान के साथ दफन है, आपको खदान के बिना बक्से खोजने की जरूरत है, और आपको हर बार एक बोनस मिलेगा',
    dt3: 'स्टार्ट पर क्लिक करें, इनपुट राशि का चयन करें, और खेलना शुरू करें',    
    dt4: 'यदि खानों से बचा नहीं जाता है, तो खेल खत्म',
  },
  // AB游戏页
  andarBahar:{
    H1: 'Andar Bahar',
    Period: 'Period',
    Bet_end: 'Bet end',
    Count_Down: 'Count Down',
    Cards: 'Cards',
    My_Bet: 'My Bet',
    Not_bet: 'Not bet',
    Rules: 'Rules',
    START: 'START',
    Wait_for_the_next_round: 'Wait for the next round!',
    Bet_Joker: 'Bet Joker',
    Suit_Of_Joker_Card: 'Suit Of Joker Card',
    Value_Of_Joker_Card: 'Value Of Joker Card',
    Bet_Deal: 'Bet Deal',
    Winning_Side: 'Winning Side',
    Cards_To_Be_Dealt: 'Cards To Be Dealt',
    Record: 'Record',
    Bet: 'Bet',
    Win: 'Win',
    VIP_Only: 'VIP Only',
    Num: 'Serial num',
    Choose: 'Choose',
    Place_your_bets:'Place your bets',
    value: 'Value',
    SuitDeal:'Suit & Deal'
  },
  gameRulesAndar:{
    h1: 'Andar Bahar Rule',
    dt1: 'Andar Bahar is a popular, easy-to-play, incredibly interesting, and unique Indian game.',
    dd1: 'The game is played live 24 hours a day and uses one standard deck of 52 cards. The game consists of two rounds.In the first round of the game the dealer reveals one card – the “joker” card – and places it on the table in a specially-marked spot. In the second round, the dealer begins to lay out one card at a time on the Andar and Bahar sides. The aim of the game is to get a card that matches the value of the “joker” card.',
    dt2: '1.First betting round',
    dd21: '1.1.Bettors can  predict the suit of the “joker” card,there are four suits (hearts ♥, diamonds ♦, spades ♠, clubs ♣)',
    dd22: '1.2.Bettors can also predict the value of the “joker” card will be over, under, or exactly 7.',
    dd23: '1.2.1.For a bet on “under 7” to win, the “joker” card must have one of the following values: A = ace, 2 = two, 3 = three, 4 = four, 5 = five, 6 = six.',
    dd24: '1.2.2.For a bet on “over 7” to win, the “joker” card must have one of the following values: 8 = eight, 9 = nine, 10 = ten, J = jack, Q = queen, K = king.',
    dd25: '1.2.3.For a bet on “exactly 7” to win, the “joker” card must have a value of 7.',
    dt3: '2.Second betting round',
    dd31: '2.1.Andar Bahar',
    dd32: '2.1.1.Andar to win',
    dd33: 'A bet on the card matching the “joker” card to be placed on to the Andar side.',
    dd34: '2.1.2.Bahar to win',
    dd35: 'A bet on the card matching the “joker” card to be placed on to the Bahar side.',
    dd36: '2.2.Number of cards dealt',
    dd37: 'A bet on how many cards will be dealt until the card matching the “joker” card is placed on to the Andar or the Bahar side',
  },

    
  // 注册协议
  privacyPolicy:{
    p1:'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    p2:'Interpretation and Definitions',
    p3:'Interpretation',
    p4:'The words of which the initial letter is capitalized have meanings defined under the following conditions.',
    p5:'The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
    p6:'Definitions',
    p7:'For the purposes of this Privacy Policy:',
    p8:'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
    p9:'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Coem Shop.',
    p10:'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
    p11:'Account means a unique account created for You to access our Service or parts of our Service.',
    p12:'Website refers to Coem Shop, accessible',
    p13:'Service refers to the Website.',
    p14:'Country refers to: Uttar Pradesh, India',
    p15:'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
    p16:'Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.',
    p17:'Personal Data is any information that relates to an identified or identifiable individual.',
    p18:'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
    p19:'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
    p20:'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
    p21:'Collecting and Using Your Personal Data',
    p22:'Types of Data Collected',
    p23:'Personal Data',
    p24:'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
    p25:'Email address',
    p26:'First name and last name',
    p27:'Phone number',
    p28:'Address, State, Province, ZIP/Postal code, City',
    p29:'Usage Data',
    p30:'Usage Data',
    p31:'Usage Data is collected automatically when using the Service.',
    p32:"Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
    p33:'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
    p34:'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
    p35:'Tracking Technologies and Cookies',
    p36:'We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.',
    p37:'You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.',
    p38:'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.',
    p39:'We use both session and persistent Cookies for the purposes set out below:',
    p40:'Necessary / Essential Cookies',
    p41:'Type: Session Cookies',
    p42:'Administered by: Us',
    p43:'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
    p44:'Cookies Policy / Notice Acceptance Cookies',
    p45:'Type: Persistent Cookies',
    p46:'Administered by: Us',
    p47:'Purpose: These Cookies identify if users have accepted the use of cookies on the Website.',
    p48:'Functionality Cookies',
    p49:'Type: Persistent Cookies',
    p50:'Administered by: Us',
    p51:'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
    p52:'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.',
    p53:'Use of Your Personal Data',
    p54:'The Company may use Personal Data for the following purposes:',
    p55:'To provide and maintain our Service, including to monitor the usage of our Service.',
    p56:'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
    p57:'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
    p58:"To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    p59:'To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
    p60:'To manage Your requests: To attend and manage Your requests to Us.',
    p61:'We may share your personal information in the following situations:',
    p62:'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
    p63:'For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.',
    p64:'With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
    p65:'With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.',
    p66:'With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see You name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.', 
    p67:'Retention of Your Personal Data',
    p68:'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
    p69:'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
    p70:'Transfer of Your Personal Data',
    p71:"Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
    p72:'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
    p73:'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
    p74:'Disclosure of Your Personal Data',
    p75:'Business Transactions',
    p76:'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
    p77:'Law enforcement',
    p78:'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
    p79:'Other legal requirements',
    p80:'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
    p81:'Comply with a legal obligation',
    p82:'Protect and defend the rights or property of the Company',
    p83:'Prevent or investigate possible wrongdoing in connection with the Service',
    p84:'Protect the personal safety of Users of the Service or the public',
    p85:'Protect against legal liability',
    p86:'Security of Your Personal Data',
    p87:'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
    p88:"Children's Privacy",
    p89:'Our Service does not address anyone under the age of 16. We do not knowingly collect personally identifiable information from anyone under the age of 16. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.',
    p90:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
    p91:'Links to Other Websites',
    p92:"Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
    p93:'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
    p94:'Changes to this Privacy Policy',
    p95:'We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
    p96:'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
    p97:'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page',
    p98:'Other',
    p99:"If the loss is caused by the user's wrong input of bank account information, the user shall be responsible for the loss.",
  },
}
