<template>
<div>
  <div  v-if="!iframeLoading" class="index-loading">
    <div class="crash-cc-loading">
      <div class="textH1">
        <div v-if="tips === 1">Bet <strong>100</strong>, fly to <strong>100X</strong>, earn<strong>₹10000</strong>!</div>
        <div v-else-if="tips === 2">Bet <strong>10000</strong>,fly to <strong>2X</strong>,earn <strong>₹20000</strong>!</div>
        <div v-else-if="tips === 3">Fail <strong>100</strong> times, earn back by <strong>100x</strong> once</div>
        <div v-else-if="tips === 4">Seize the opportunity, you will be the next <strong>millionaire</strong>!</div>
        <div v-else-if="tips === 5">The rocket can fly <strong>100X</strong>, and the upper limit of your investment return is also 100X</div>
        <div v-else-if="tips === 6">Today is an <strong>opportunity</strong> to get better. Don't waste it</div>
      </div>
      <div class="text">{{ ldText }}</div>
      <div class="cc-loadbox">
        <div class="cc-loadbox-c" :style="{ right: rightPx + 'px' }"><span>{{ rightText }}%</span></div>
      </div>
    </div>
    
  </div>

  <div class="iframe-box" :style="{ height: iframeHeight }">
    <iframe  width="100%" height="100%" :src="iframeSrc" class="iframe" ref="child"></iframe>
    <!-- <div class="cc-nav">
      
      <button class="btn s1"  @click="toRecharge">{{$t('home.recharge')}}</button>

      <button class="btn s2"  @click="toInvite">{{$t('global.invite')}}</button>

      <button class="btn s3"  @click="toTaskReward">{{$t('global.reward')}}</button>

      <button class="btn s4"  @click="toHome">{{$t('home.h1')}}</button>
    </div> -->
  </div>

  <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

  <nut-popup pop-class="pop-withdrawal" v-model:visible="withdrawalPopup" :z-index="100">
        <div class="hd">
          {{ $t('game.pleaseWithdraw1') }}<strong style="color: #163f00;">{{ $t('game.pleaseWithdraw2') }} <strong style="color:#d66000">{{ commonInfo.forceWithdrawThreshold }}+ </strong></strong>
        </div>
        <div class="bd">
          <span style="color: #163f00;
            line-height: 14px;
            display: block;
            padding: 6px 0;
            border-radius: 5px;
            margin: 5px 0;"
            >{{ $t('game.pleaseWithdraw4') }}</span>
        </div>
        <div class="btn" style="text-align: center;">
          <nut-button type="primary" @click="withdrawClick" style="width: 45%;">{{ $t('game.go') }}</nut-button>
          <span v-show="gotitBtn" class="link" @click="withdrawalPopup = false">{{ $t('game.gotitBtn') }}</span>
        </div>
    </nut-popup>


    <nut-popup pop-class="pop-invite" v-model:visible="invitePopup" :z-index="100">
        <div class="hd">{{ $t('game.info1') }}</div>
        <div class="bd">
          {{ $t('game.info2') }}
          <strong>{{ $filters.currencySymbol(fillUpAmount) }}</strong>
          <div class="app-task" v-show="appTaskStatus === 'forbid' || appTaskStatus === 'can_receive'" @click="downloadApp">
            {{ $t('global.DOWNLOAD_APP_TEXT2') }}
            <span>{{ $filters.currencySymbol(appTaskReward) }}</span>
            {{ $t('global.DOWNLOAD_APP_TEXT3') }}
          </div>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="invitePopup = false">I got it</nut-button>
        </div>
      </nut-popup>
    </div>
</template>

<script>

import { isNotBlank } from "@/assets/js/commonUtil";
import { getCountry, getGameUrl, setLastTimeGameName } from "@/assets/js/environment";
import { getDeviceId } from "@/assets/js/visitor";
import axios from "axios";
import {Dialog, Toast} from "@nutui/nutui";
import {isBlank} from "@/filters";
import router from "@/router";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";
import {eventTrackingPay, eventTrackingWithdraw} from "@/assets/js/eventTracking";
import TabbarMain from "../components/TabbarMain.vue";

export default {
  name: "crashHome",
  components: {
    tabbar: TabbarMain,
  },
  
  data() {
    return {
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      iframeLoading:false,
      country: '',
      token: '',
      vtoken: '',
      ccUrl: '',
      iframeSrc: '',
      iframeHeight:'480px',
      tabbarActiveIndex: 2,
      ccDataLocked: true,
      gau: '',
      gwu: '',
      withdrawalPopup: false,
      invitePopup: false,
      fillUpAmount: 151, //补满金币值
      showDialogFill: true,
      appTaskStatus: '',
      appTaskReward: 0,
      rightPx: 200,
      rightText: 0,
      ldText:'The first loading may take some time, please wait.',
      tips:1,
      tipsMax:0,
    }
  },
  methods: {
    async initPage() {
      const deviceId = await getDeviceId();
      localStorage.setItem("di", deviceId);
      return axios.get("/api/crash2/visit", {})
    },

    /**
     * 到达卡点金额，强制提现
     */
     showNeedWithdrawDialog() {
      //if(this.player.rechargeAmount > 0){this.gotitBtn = true}
      this.withdrawalPopup = true
    },

    /**
     * 提示邀请补满
     */
     showFillUpTipsIfSuitable() {
      //未充值过且钱包余额不足
      if (this.showDialogFill) {
          this.showDialogFill = false
          setTimeout(() => {
            this.invitePopup = true
          }, 2000)
        }
    },

    downloadApp(){
      window.open(getCommonInfoAttr('appDownloadUrl'))
    },

    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },

    messageCC(ev){
      const data = ev.data.crashData || null;
      this.ccData(data);
    },
    ccData(d){
      if(!this.ccDataLocked){
        return
      }
      this.ccDataLocked = false
      if(d._type === 'url'){
        switch (d._data) {
          case 'parentPage_loading':
            this.iframeLoading = true
            break;

          case 'parentPage_showInvite':
            this.showFillUpTipsIfSuitable()
            break;

          //回主页
          case 'parentPage_home':
              this.$router.push({name: "myHome",});
              break;

          //我的历史游戏
          case 'parentPage_playList':
              this.$router.push({name: "myPlayList",});
              break;

          //去提现
          case 'parentPage_withdrawal':
              this.$router.push({name: "withdrawalBalance",});
              break;

          //去充值
          case 'parentPage_recharge':
              this.$router.push({name: "rechargeBalance",});
              break;

          //去邀请
          case 'parentPage_invite':
              this.$router.push({name: "agentPromotion",});
              break;

          //注册
          case 'parentPage_userRegister':
              this.$router.push({name: "userRegister",});
              break;

          //登录
          case 'parentPage_login':
              this.$router.push({name: "userLogin",});
              break;  
              
          //appDownload
          case 'app_download':
              window.open(getCommonInfoAttr('appDownloadUrl'))
              break;
          default:

            break;
        }
      }else if(d._type === 'apiMessage'){
          if(isBlank(d._data)){
            return
          }
          //站内信
          if (isNotBlank(d._data.systemLetter)) {
            d._data.systemLetter.forEach(letter => {
                  Dialog({
                      title: letter.title,
                      content: letter.content,
                      okText: 'OK',
                      noCancelBtn: true
                  })
              });
          }

          //充值成功
          if (isNotBlank(d._data.rechargeSuccess)) {
            d._data.rechargeSuccess.forEach(recharge => {
                  eventTrackingPay(recharge.inviteCode, recharge.inviterId, recharge.rechargeAmount, recharge.firstTime);
                  if(recharge.firstTime === true){
                      let Download_APP_TEXT = 'Download APP'
                      let INFO_TEXT = 'For a better game experience, get rewards faster and get valuable information'
                      let Download_BTN_TEXT = 'Download'
                      let Cancel_BTN_TEXT = 'Cancel'
                      if(this.country === 'br'){
                          Download_APP_TEXT = 'Baixar o APP'
                          INFO_TEXT = 'Para uma melhor experiência de jogo, receba recompensas mais rapidamente e obtenha informações valiosas'
                          Download_BTN_TEXT = 'Baixar'
                          Cancel_BTN_TEXT = 'Cancelar'
                      }
                      Dialog({
                          title: Download_APP_TEXT,
                          content: INFO_TEXT,
                          okText: Download_BTN_TEXT,
                          cancelText: Cancel_BTN_TEXT,
                          onOk: () => {
                              let c = JSON.parse(window.localStorage.getItem("commonInfo"));
                              let u = c.appDownloadUrl;
                              window.open(u);
                          }
                      });
                  }
              });
          }
          //提现成功
          if (isNotBlank(d._data.withdrawSuccess)) {
            d._data.withdrawSuccess.forEach(withdraw => {
                  eventTrackingWithdraw(withdraw.inviteCode, withdraw.inviterId, withdraw.withdrawAmount);
              });
          }


      }else if(d._type === 'apiCode'){

          switch (d._data.code) {
              case 1001:
                  this.$router.push({name: "userRegister",});
                  break;
              case 1002:
              case 1003:
                  Toast.fail('Occur an error.');
                  break;
              case 1004:
                  break;
              case 2000:
                  if (isBlank(d._data.bizCode)) {
                      Dialog({
                          content: d._data.msg,
                          okText: 'OK',
                          noCancelBtn: true
                      });
                  } else if (d._data.bizCode === 'CRASH_WAIT_NEXT_ROUND') {
                      //已经是飞行阶段了
                      Toast.text(this.$t('game.waitForTheNextRound'));
                  } else if (d._data.bizCode === 'GAME_BET_NEED_WITHDRAW') {
                      //需要提现
                      this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'))

                  } else if (d._data.bizCode === 'FUND_ACCOUNT_AMOUNT_NOT_ENOUGH') {
                      let recharge_txt = this.country === 'br' ? 'recarga' : 'Recharge'
                      Dialog({
                          content: d._data.msg,
                          okText: recharge_txt,
                          cancelText: 'OK',
                          onOk: () => {
                              this.$router.push({name: "rechargeBalance",});
                          }
                      });
                  }
                  break;
              case 9999:
                  Toast.fail('Server error. Please try later.');
                  break;
          }
      }else if(d._type === 'invite'){
        this.fillUpAmount = d._data
        this.showFillUpTipsIfSuitable()
      }

      this.ccDataLocked = true
    },
    //拦截响应
    interceptorsResponse(){
    },
    //充值
    toRecharge() {
      this.$router.push({
          name: "rechargeBalance",
        });
    },
    //邀请
    toInvite() {
      this.$router.push({
          name: "agentPromotion",
        });
    },
    //任务
    toTaskReward() {
      this.$router.push({
          name: "taskReward",
        });
    },
    //回主页
    toHome() {
      this.$router.push({
          name: "myHome",
        });
    },

    
  },
  mounted() {
    this.gau = document.querySelector('body').getAttribute('gau');
    this.gwu = document.querySelector('body').getAttribute('gwu');
    this.country = getCountry()
    this.ccUrl = getGameUrl()

    setLastTimeGameName('crash')

    this.initPage().then((response) => {
      if(isNotBlank(response.data.data.appTaskStatus)){
        this.appTaskStatus = response.data.data.appTaskStatus
      }
      if(isNotBlank(response.data.data.appTaskStatus)){
        this.appTaskReward = response.data.data.appTaskReward
      }

      //设置屏幕高度
      if(window.innerHeight > 480){
        this.iframeHeight = window.innerHeight + 'px';
      }
      if (isNotBlank(response.data.data.vtoken)) {
        localStorage.setItem("vtoken", response.data.data.vtoken);
        this.iframeSrc = this.ccUrl + '/?vtoken=' + response.data.data.vtoken + '&country=' + this.country + '&gau=' + this.gau + '&gwu=' + this.gwu
        if(isNotBlank(this.appTaskStatus)){
          this.iframeSrc += '&appTaskStatus=' + this.appTaskStatus + '&appTaskReward=' + this.appTaskReward
        }
      }else{
        this.token = localStorage.getItem("token");
        this.iframeSrc = this.ccUrl + '/?token=' + this.token + '&country=' + this.country + '&gau=' + this.gau + '&gwu=' + this.gwu
        if(isNotBlank(this.appTaskStatus)){
          this.iframeSrc += '&appTaskStatus=' + this.appTaskStatus + '&appTaskReward=' + this.appTaskReward
        }
      }
      
    });

    this.tips = parseInt(Math.random() * 6 + 1)

    window.addEventListener('message', this.messageCC, false);


    const timer = setInterval(() => {
      if (this.rightText <= 50) {
        this.rightText += 4
        this.rightPx -= 8
      } else if(this.rightText <= 80) {
        this.rightText += 3
        this.rightPx -= 6
      } else if(this.rightText <= 98) {
        this.rightText += 1
        this.rightPx -= 2
        this.ldText = 'Almost there,please wait a little longer.'
      }else if(this.rightText >= 99) {
        window.clearInterval(timer);
      }
    }, 400);
    const timer2 = setInterval(() => {
      this.tips = parseInt(Math.random() * 6 + 1)
      this.tipsMax ++
      if(this.tipsMax > 15 || !this.iframeLoading){
        window.clearInterval(timer2);
      }
    }, 2000);
  },
  beforeRouteLeave() {

  },
  unmounted(){
    window.removeEventListener('message', this.messageCC, false)
  },


};
</script>

<style scoped>
  .iframe-box{ width: 100%; height: 100%;}
  .iframe{ width: 100%; height: 100%; position: relative; border: 0 !important; margin: 0; padding: 0;}

  .cc-nav{  height: 30px;  position: fixed; bottom: 0; left:0; right: 0;}
  .cc-nav .btn{ color: #fff; height: 30px; overflow: hidden; vertical-align: top; border: 0; border-radius: 3px 3px 0 0; margin-right: 0.5%; margin-left: 0.5%; width: 24%;
    background: linear-gradient(315deg,#85991e 0%, #457b3d 50%,#85991e 100%);} 

  .crash-cc-loading{ color: #fff; position: fixed; top: calc(50% - 200px); text-align: center; width: 100%; height: 210px; background:none;}
  .crash-cc-loading .text{ line-height: 20px; height: 30px;color:#6d8088; margin-top: 20px;}
  .cc-loadbox{ width: 200px; height: 16px; background: #51595d; border: 3px solid #334046; margin: 0 auto; border-radius: 12px; position: relative; overflow: hidden; }
  .cc-loadbox-c{ height: 100%; width: 200px;   background: linear-gradient(315deg, #0074b1 0%, #028ad2 100%);border-radius:0 12px 12px 0; text-align: right; line-height: 14px;
     position: absolute; top: 0; bottom:0;}
  .cc-loadbox-c span{ padding-right: 10px; color: #42ff00; font-size: 12px; line-height: 14px;}
  .in .index-loading{  background: #000 url(../assets/images/in/EN/S2/toplogo.png) center 28% no-repeat; background-size:140px auto;}
  .textH1{ font-size: 22px; color: #5dbcff;    margin-top: 100px; padding: 0 20px; min-height: 80px;}
  .textH1 strong{ color: #fffb00;font-size: 26px;}

</style> 
