<template>
  <div class="pop-vip">
    <div class="pophd">
      <strong>VIP {{$t('invite.rules')}}</strong>
    </div>
    <div class="popbd">
      
      <div class="table-tab">
        <span :class="w_privilege ? 'a' : ''" @click="tabClick('w_privilege')">VIP</span>
        <span :class="c_privilege ? 'a' : ''" @click="tabClick('c_privilege')">Crash</span>
<!-- 
        <span :class="p_privilege ? 'a' : ''" @click="tabClick('p_privilege')">Parity</span>
        <span :class="a_privilege ? 'a' : ''" @click="tabClick('a_privilege')">A&B</span>
        <span :class="wh_privilege ? 'a' : ''" @click="tabClick('wh_privilege')">Wheel</span>
        <span :class="m_privilege ? 'a' : ''" @click="tabClick('m_privilege')">Mine</span> -->
         
      </div>
      <table class="table-th">
        <tr>
          <th>{{$t('VIP_PRIVILEGE.LEVEL')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.TOTAL_RECHARGE')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.WITHDRAWAL_QUOTA')}}</th>

          <th v-show="c_privilege || p_privilege">{{$t('VIP_PRIVILEGE.DAILY_BONUS')}}</th>
          <th v-show="c_privilege">&#128640; {{$t('VIP_PRIVILEGE.STOP_LIMIT')}}</th>
          <th v-show="c_privilege">&#128718; {{$t('VIP_PRIVILEGE.INPUT_LIMIT')}}</th>
          
          <th v-show="p_privilege">{{$t('VIP_PRIVILEGE.DATA_ANALYSIS')}}</th>
          <th v-show="p_privilege">&#127914; {{$t('VIP_PRIVILEGE.ROOM_LIMIT')}}</th>
          <th v-show="a_privilege || wh_privilege || m_privilege">&#128176; {{$t('VIP_PRIVILEGE.INPUT_LIMIT')}}</th>
          <th v-show="a_privilege">&#127374; Gameplay</th>
        </tr>
        <!--
        <tr v-for="(item, index) in vipRules" :key="index" :class="myLevel === index ? 'mylevel' : ''">
          <td>Lv{{item.level}}</td>
          <td v-show="!showPrivilege">{{ $filters.currencySymbol(item.requireRechargeAmount) }}</td>
          <td v-show="!showPrivilege">{{ $filters.currencySymbol(item.withdrawLimit) }}</td>
          <td v-show="showPrivilege">+{{item.extraDailySignRewardRatio}}%</td>
          <td v-show="showPrivilege"><span v-if="index === 4">+{{registerReward}} Extra</span><span v-else>--</span></td>
          <td v-show="showPrivilege"></td>
        </tr>
        -->
        <tr v-for="(item, index) in vipRules" :key="index" :class="myLevel === index ? 'mylevel' : ''">
          <td>VIP{{item.level}}</td>
          <td v-show="w_privilege">
            {{ $filters.currencySymbol(item.requireRechargeAmount) }}
            <span v-show="
              (country === 'br' && item.level === 3 && s !== 's2') || 
              (country !== 'ng' && country !== 'br' && item.level === 4)
            ">(+{{registerReward}} &#10004;)</span>
          </td>
          <td v-show="w_privilege">
            {{ $filters.currencySymbol(item.withdrawLimit) }}
          </td>

          <td v-show="c_privilege || p_privilege">&#127873; +{{item.extraDailySignRewardRatio}}%</td>
          <td v-show="c_privilege">{{$t('VIP_PRIVILEGE.MAX')}} {{item.crashMaxRatio}}x</td>
          <td v-show="c_privilege">{{$t('VIP_PRIVILEGE.MAX')}} &#128176;{{item.maxBetAmount}}</td>
          <td v-show="p_privilege">
            <span v-if="index === 0">{{$t('VIP_PRIVILEGE.RECORD')}}</span>
            <span v-else-if="index === 1">{{$t('VIP_PRIVILEGE.CONTINUOUS')}}</span>
            <span v-else-if="index === 2">{{$t('VIP_PRIVILEGE.PROBABILITY')}}</span>
            <span v-else>--</span>
          </td>
          <td v-show="p_privilege">
            <span v-if="index === 0">&#9889;Fast</span>
            <span v-else-if="index === 1">&#128161;Prediction</span>
            <span v-else>--</span>
          </td>

          <td v-show="a_privilege || wh_privilege || m_privilege">
            <span v-if="index === 0 && country === 'br'">Max {{ wh_privilege ? '10' : '5' }}</span>
            <span v-else-if="index === 0">Max {{ country === 'in' ? '50' : '5' }}</span>
            <span v-else>Max 1000</span>
          </td>

          <td v-show="a_privilege">
            <span v-if="index === 0">{{ $t('andarBahar.value') }} & Andar Bahar</span>
            <span v-else-if="index === 1">{{ $t('andarBahar.SuitDeal') }}</span>
            <span v-else>{{$t('game.all')}}</span>
          </td>
        </tr>
      </table>
      <p class="p">
        <!-- {{$t('VIP_PRIVILEGE.TEXT1')}}{{registerReward}}{{$t('VIP_PRIVILEGE.TEXT2')}} -->
      </p>
      <p v-show="upgradeVip === 'show'" style="margin: 5px;color: rgb(238, 238, 19) !important;font-size: 12px; line-height: 16px; font-weight: 600;">{{$t('VIP_PRIVILEGE.TEXT3')}}</p>
    </div>
  </div> 
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import axios from "axios";
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'VipRules',
  props: {
    myLevel:{
      type: Number,
      default : 0
    },
    popTitle:{
      type: String,
      default : ''
    },
    upgradeVip:{
      type: String,
      default : ''
    },
    tabActive:{
      type: String,
      default : ''
    },
  },
  watch:{
    tabActive: function(newData){
      if(newData === 'parity'){
        this.w_privilege = false
        this.c_privilege = false
        this.p_privilege = true
        this.a_privilege = false
      } else if(newData === 'crash') {
        this.w_privilege = false
        this.c_privilege = true
        this.p_privilege = false
        this.a_privilege = false
      } else if(newData === 'andar') {
        this.w_privilege = false
        this.c_privilege = false
        this.p_privilege = false
        this.a_privilege = true
      }
    }
  }, 
  data() {
    return {
      country: '',
      s:'s1',
      w_privilege: true,
      c_privilege: false,
      p_privilege: false,
      a_privilege: false,
      wh_privilege: false,
      m_privilege: false
    };
  },
  methods: {
    tabClick(t){
      this.w_privilege = false
      this.c_privilege = false
      this.p_privilege = false
      this.a_privilege = false
      this.wh_privilege = false
      this.m_privilege = false
      switch (t) {
        case 'w_privilege':
          this.w_privilege = true
          break;
        case 'c_privilege':
          this.c_privilege = true
          break;
        case 'p_privilege':
          this.p_privilege = true
          break;
        case 'a_privilege':
          this.a_privilege = true
          break;
        case 'wh_privilege':
          this.wh_privilege = true
          break;
        case 'm_privilege':
          this.m_privilege = true
          break;
      }
    }
  },  
  created() {
    this.country = getCountry()
    this.s = document.querySelector('body').getAttribute('s')
  },
  setup() {
    const state = reactive({
      vipRules:'',
      registerReward:''
    });
    onMounted(() => {
      if (window.localStorage.getItem("commonInfo")) {
        let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
        state.vipRules = commonInfo.vipRules;
        state.registerReward = commonInfo.registerReward;
      } else {
        axios.get("/api/system/common", {}).then((response) => {
          state.vipRules = response.data.data.vipRules;
          state.registerReward = response.data.data.registerReward;
        });
      }
    });
    return {
      ...toRefs(state)
    };
  }

};
</script>

<style scoped>
  .popbd .p{ color: #666; padding:0px 5px; line-height: 14px; margin: 10px 0 0 0; font-size: 10px;}
.table-tab { display: flex;}
.table-tab span{ flex-basis:auto; flex-grow: 1; background: #c9dee0; margin-right:3px; padding: 4px 8px; text-align: center;
   line-height: 20px; border-radius:5px 5px 0 0; color: #2aafd4; font-size: 12px;}
  .table-tab span:last-child{margin-right:0px;}
.table-tab span.a{ background: #fff;color:#ff8503;}
.pop-vip .pophd{height: 50px; padding-top:10px; text-align: center;}
.pop-vip .pophd strong{ line-height: 30px; text-align: center; color: #ff8503; font-size: 14px; 
  background: url(../assets/images/bg96.png) 0 center no-repeat; background-size: 20px; padding: 5px 0 5px 30px;}
.pop-vip .popbd{  padding: 5px;}
.table-th{ width: 100%;border-radius:0 0 8px 8px; overflow: hidden; margin-top: -1px;}
.table-th th{border: 1px solid #eee; line-height: 10px; text-align: center;line-height: 14px; 
  padding: 10px 3px; font-size: 10px; background: #fff; border-bottom:2px solid  #348fca; font-weight: 300;font-size: 14px;}
.table-th td{border: 1px solid #eee; padding: 0 0 0 5px; line-height: 16px; font-size: 12px;background: #fff; text-align: center;height: 26px; text-align: left;}
.table-th tr td:nth-child(3){  background:rgb(242, 255, 244); font-weight: 500; color: rgb(0, 101, 32);}
.table-th tr.mylevel td{ background-color: #f0f9bf !important;}
</style>
<style>
.pop-vip { width:390px !important; padding: 0px !important; background:#b6d7ec !important;border-radius:8px;}
</style>
