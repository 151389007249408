<template>
  <nut-tabbar v-model:visible="active"  :bottom="true" :safeAreaInsetBottom="true" :unactiveColor="unactiveColor" :activeColor="activeColor">
    <!-- @tab-switch="tabSwitch"  --> 
    
    <nut-tabbar-item :tab-title="$t('global.recharge')" to="./rechargeBalance"  :img="tabbarImg" :activeImg="tabbarActiveImg"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.invite')"   to="./agentPromotion"  :img="tabbarImg2" :activeImg="tabbarActiveImg2" ></nut-tabbar-item>

    <nut-tabbar-item tab-title=" " :img="lobbyBtn" :activeImg="lobbyBtn" @click="lobbyBtnClick" class="show-list"></nut-tabbar-item>

    <nut-tabbar-item :tab-title="$t('global.reward')"   to="./taskReward"      :img="tabbarImg3" :activeImg="tabbarActiveImg3"  ></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.my')"       to="./myHome"           :img="tabbarImg4" :activeImg="tabbarActiveImg4" ></nut-tabbar-item>
    
  </nut-tabbar>
</template>
<script>
  import { reactive, toRefs, onMounted } from "vue";
  import {useRouter} from "vue-router";
  export default{
    props:[
      'activeIndex'
    ],
    data() {
      return {};
    },
    created() {},
    methods: {
    },
    setup(props) {
      const data = reactive({
        commonInfo: '',
        homeUrl: '',
        unactiveColor:"#C8C4D9",
        activeColor:"#5c2766",
        tabbarImg: require('../assets/images/bg03.png'),
        tabbarActiveImg: require('../assets/images/bg03-a.png'),

        tabbarImg2: require('../assets/images/bg03a.png'),
        tabbarActiveImg2: require('../assets/images/bg03a-a.png'),

        tabbarImg3: require('../assets/images/bg03b.png'),
        tabbarActiveImg3: require('../assets/images/bg03b-a.png'),

        tabbarImg4: require('../assets/images/bg03c.png'),
        tabbarActiveImg4: require('../assets/images/bg03c-a.png'),

        notImg: require("../assets/images/bg04.png"),
        lobbyBtn: require('../assets/images/bg76.png'),
        active: props.activeIndex,
      });
      const router = useRouter();
      const methods = {
        goToGame(n){
          router.push({name: n});
        },
        lobbyBtnClick(){
          if(data.commonInfo.games.length > 1){
            //router.push({name: 'gameLobby'});
            router.push({name: 'crash'});
          }else{
            router.push({name: 'crash'});
           // router.push({path: data.homeUrl});
          }
          //router.push({path: 'crash'});
        },
      }
      onMounted(() => {
        data.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"))
        data.homeUrl = '/' + data.commonInfo.games[0].name
      })
      return {
        ...toRefs(data),
        ...methods,
       // tabSwitch,
      };
    },
  }
  
</script>

<style scoped>
  .nut-tabbar{
    padding-top: 5px;
    padding-bottom: 0px;
    border-top:0;
    background: url(../assets/images/gm1b.png) center top no-repeat;
    height: 63px !important;
  }
  .nut-tabbar-bottom.nut-tabbar-safebottom{
    height: 45px !important;
    background: #0a222c;
  }
  .nut-tabbar-item{color: #0c3901; padding-top: 0px;}
  .show-list{
    margin-top: -7px;
    background: none;
    background-size: 60px;
    width: 75px; min-width: 75px;
    height:45px;
    padding: 0 3px;
  }

</style>
<style>
  .show-list .nut-tabbar-item_icon-box .nut-tabbar-item_icon-box_icon{ width: 69px !important; height: 69px !important;}
</style>
