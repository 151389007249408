<template>
  <div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="iconClick"
    class="mb0 nav-icon-right nut-navbar--fixed top-desc"
    :title="$t('recharge.h1')"
    titIcon="horizontal"
    fixed=true
  >
  </nut-navbar>

    <div class="recharge-bd balanceVue">

      <div class="recharge-ls" style="padding-top:40px">

        <div
          class="recharge-btn"
          @click="rechargeBtnClick(item)"
          :class="{ active: item.defaultPlan }"
          v-for="(item, index) in plans"
          :key="index"
        >
          <strong>{{ $filters.currencySymbol(item.rechargeAmount) }}</strong>
          <span class="hot" v-show="item.hot">&nbsp;</span>
          <p v-show="item.giftAmount">+{{ item.giftAmount }}</p>
        </div>
        
      </div>
      <nut-form>
        <nut-form-item :label="$t('recharge.amount')">
          <input
            class="nut-input-text"
            :placeholder="$t('recharge.amountPlaceholder')"
            type="text"
            v-model="rechargeInputText"
            v-on:input="inputChange"
            :readonly="!allowCustomAmount"
          />
          <p class="p-info" v-show="rechargeInputText > 0">
            +{{channelList.giftAmount}}
          </p>
        </nut-form-item>

        <nut-form-item :label="$t('recharge.receipt')">
          <input
            v-show="rechargeInputText > 0"
            class="nut-input-text"
            :placeholder="$t('recharge.receiptPlaceholder')"
            type="text"
            readonly="true"
            v-model="channelList.receiptAmount"
          />
        </nut-form-item>
        <nut-form-item :label="$t('recharge.channel')">
          <nut-radiogroup direction="horizontal" v-model="radio" class="radio-list">
            <nut-radio
              :id= "index === 0 ? 'radioaActive' : ''"
              :label="item.id"
              v-for="(item, index) in channelList.channelRoutes"
              :key="index"
              v-bind:c-type="item.type"
              :class="item.lastSuccess ? 'last-success' : ''"
              @click="channelType(item.id)"
              :checked="{ checked: index === 1 }">
              {{ item.typeName }}
            </nut-radio>


          </nut-radiogroup>
        </nut-form-item>
        <!-- <nut-form-item label="Channel(C2C)" v-if="c2cRechargeOpen">
          <nut-radiogroup direction="horizontal" v-model="radio" class="radio-list">
            <nut-radio
              id="9999"
              label="C2C"
              v-bind:c-type="c2c"
              @click="channelType(9999)">C2C</nut-radio>
          </nut-radiogroup>
        </nut-form-item> -->
      </nut-form>

      

      <div class="login-btn m10" style="margin-top: 20px;">
        <nut-button block type="info" @click="rechargeApply" :disabled="rechargeing">
          <nut-icon v-if="rechargeing" name="loading" size="25" style="margin-top: 15px;"></nut-icon>
          <span v-else>{{$t('global.recharge')}}</span>
        </nut-button>
      </div>


      <div class="telegram-btn" @click="telegramClick">
        <img src="../assets/images/bg208.png">
      </div>

      <nut-noticebar 
          direction='vertical' 
          :list="othersInfo" 
          :speed='10' 
          :standTime='2000' 
          :closeMode="true" 
          :color="`#ff7e00`">
      </nut-noticebar>
       
      <div class="bt-tx">
        <p><strong>{{$t('withdrawal.description')}}</strong></p>
        <p>{{$t('recharge.tx5')}} {{ eMail }}, {{$t('recharge.tx5_2')}} </p>
        <strong  v-if="commonInfo.supportCenterOn" style="color:#e4e64e; background: #005200; padding: 5px 8px; border-radius: 5px; margin:0 auto 5px auto; display: inline-block;" class="text-color"  @click="supportCenterClick">Support Center</strong>
        <p>{{$t('recharge.tx6')}} “VIP Descriptions”</p>
        <strong style="color:#f1ae49; background: #10776b; padding: 5px 8px; border-radius: 5px; margin:0 auto 5px auto; display: inline-block;" class="text-color" @click="vipPopupClick">{{$t('recharge.tx8')}}</strong>
        <p>{{$t('recharge.tx9')}}</p>
      </div>
      <nut-popup pop-class="popclass pop-h200" closeable round close-on-click-overlay='false' :style="{ padding: '20px 40px',width:'70%' }" 
        v-model:visible="showPopup" :z-index="100" @close="popClose">
        <p>{{$t('recharge.pop')}}</p>
        <div>
          <span>Secure</span>
          <span>Fast</span>
        </div>
      </nut-popup>
    </div>
    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle"></VipRules>
    </nut-popup>

    <!--**************************************** 活动弹框 ****************************************-->
    <nut-popup pop-class="pop-ab" v-model:visible="abPopup">
      <div class="bd" style="background: none !important;" >

        <nut-swiper v-if="country === 'in'" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="6000" :is-preventDefault="false">
          <nut-swiper-item  @click="abPopup = false; setCookie('RECHARGE_PROMOTIONS11', 'yes',3);">
            <img  :src="require('../assets/images/bg901.png')" />
          </nut-swiper-item>
        </nut-swiper>

      </div>
      <nut-button type="primary" @click="abPopup = false">{{ $t('game.gotitBtn') }}</nut-button>
    </nut-popup>







    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>

  <form id="payForm" name="payForm" method="post" hidden></form>


  

</template>

<script>
import { reactive, toRefs, onMounted, watch, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import VipRules from "../components/VipRules2.vue";
import { getCountry,getLastTimeGameName } from "@/assets/js/environment";
import {getRootUrl} from "@/assets/js/commonUtil";



export default {
  components: {
    VipRules: VipRules,
    tabbar: TabbarMain,
  },
  data(){
    return{
      country:'',
      template:'',
      eMail:'',
      imgUrl:'',
      commonInfo:'',
      telegramChannelUrl:'',
      dateMonth: '11', //月
      startDay: '1',
      endDay: '1',
      startShow: false,
      abPopup: false,
      i18n:{
        loading: this.$t('global.loading'),
        networkError: this.$t('global.networkError'),
        failSelect: this.$t('recharge.failSelect'),
        failEnter: this.$t('recharge.failEnter'),
        success: this.$t('recharge.success'),
        popTitle_1: this.$t('recharge.popTitle_1'),
        popTitle_2: this.$t('recharge.popTitle_2'),
        successfully: this.$t('global.successfully'),
        rechargeBtnText: this.$t('global.rechargeBtnText'),
        errInfoRequired: this.$t('global.errInfoRequired'),
        INVALID_INPUT_VALUE: this.$t('global.INVALID_INPUT_VALUE')
      }
    }
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.template = document.querySelector('body').getAttribute('s')
    
    if(window.localStorage.getItem('commonInfo')){
      this.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
      this.eMail = this.commonInfo.serviceEmail
      this.telegramChannelUrl = this.commonInfo.telegramChannelUrl

    }else{
      axios.get("/api/system/common", {}).then((response)=> {
        this.eMail = response.data.data.serviceEmail
        this.telegramChannelUrl = response.data.data.telegramChannelUrl
      })
    }
    this.s = document.querySelector('body').getAttribute('s');
    const d = new Date()
    
    if(this.country === 'br' && this.s === 's2'){
      this.startShow = ((d.getMonth() +1) >= this.dateMonth) && (d.getDate() >= this.startDay) && (d.getDate() < this.endDay)
      if(this.startShow){
        this.abPopup = false
      }
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      vipPopup:false,
      popTitle:'',
      allowCustomAmount:true,
      c2cRechargeOpen: false,
      showPopup: false,
      radio: 0,
      tabbarActiveIndex: 0,
      availableAmount: 0,
      availableAmount2: 0,
      rechargeInputText: 0,
      channelRouteId: 0,
      purpose: null,
      plans: [],
      myLv: "",
      channelList: [],
      othersInfo: [],
      othersInfoList: [],
      rechargeing:false,
      formData: [],
      formUrl:'',
      boundCpf: false,
      needCpf: false,
      cpfPopup: false,
      cpfInput: '',
      country2: getCountry()
    });
    watch(
      () => state.rechargeInputText,
      () => {
        if (state.rechargeInputText > 0) {
          axios({
            url: "/api/fund/recharge/prepare",
            method: "get",
            params: { rechargeAmount: state.rechargeInputText },
          }).then((res) => {
            state.channelList = res.data.data;
          });
        }
      }
    );

    const router = useRouter();
    const methods = {
      vipPopupClick(){
        state.vipPopup = true;
        state.popTitle = _data.data.i18n.popTitle_1
      },
      telegramClick(){
        window.open(_data.data.telegramChannelUrl, "_self");
      },
      toRechargeUsdt(){
        router.push({
          name: "rechargeUSDT",
        });
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      iconClick() {
        router.push({
          name: "rechargeHistoryList",
        });
      },
      supportCenterClick(){
        router.push({
          name: "helpSupport",
        });
      },
      rechargeBtnClick(t) {
        this.rechargeInputText = t.rechargeAmount;
        state.channelRouteId = 0;
        this.plans.forEach(function (obj) {
          obj.defaultPlan = false;
        });
        t.defaultPlan = !t.defaultPlan;
        methods.radioaActiveClick()
      },
      radioaActiveClick() {
        var e = document.createEvent("MouseEvents");
        e.initEvent("click", true, true);
        document.getElementById("radioaActive").dispatchEvent(e);
      },
      channelType(id){
        state.channelRouteId = id
      },
      submitPost(){
      },
      rechargeApply() {
        // if(state.country2 === 'br' && !state.boundCpf){
        //   Toast.fail("信息不全");
        //   return;
        // }
        if(state.country2 === 'br' && state.needCpf){
          state.cpfPopup = true
          return;
        }
        if (state.channelRouteId === 0) {
          Toast.fail(_data.data.i18n.failSelect);
          return;
        }
        if (state.channelRouteId === 9999) {
          axios
            .post("/api/fund/recharge/c2c/apply", {
              applyAmount: state.rechargeInputText,
              purpose: state.purpose,
            })
            .then((response) => {
              if (response.data.code === 0) {
                router.push({
                  path: "rechargeC2C",
                  query: {
                    a: response.data.data.applyAmount,
                    o: response.data.data.orderNum,
                    i: response.data.data.receiveInfo,
                  }
                })
                state.rechargeing = false;
              } else {
                state.rechargeing = false;
              }
            })
          return;
        }
        if (state.rechargeInputText <= 0) {
          Toast.fail(_data.data.i18n.failEnter);
          return;
        }
        if (state.rechargeInputText === '') {
          Toast.fail(_data.data.i18n.failEnter);
          return;
        }
        state.rechargeing = true;
        //const res = await axios
        axios
          .post("/api/fund/recharge/apply", {
            applyAmount: state.rechargeInputText,
            channelRouteId: state.channelRouteId,
            purpose: state.purpose,
            returnUrl: `${getRootUrl()}/rechargeHistoryList`
          })
          .then((response) => {
            if (response.data.code === 0) {
              if(response.data.data.type === "redirect"){
                state.showPopup = true
                window.open(response.data.data.redirectUrl,"_self");
              }else if(response.data.data.type === "form"){
                  state.formData = response.data.data.formParams
                  state.formUrl = response.data.data.formUrl
                  var f = document.getElementById("payForm")
                  f.setAttribute("action",state.formUrl);
                  for (const key of response.data.data.formParams.keys()) {
                      const n = response.data.data.formParams[key].name
                      const v = response.data.data.formParams[key].value
                      if (n != 'formUrl' && n != 'formRequestMethod') {
                          var i = document.createElement("input")
                          i.setAttribute("name",n);
                          i.setAttribute("value",v);
                          f.appendChild(i)
                      }
                  }
                  document.forms['payForm'].submit()
              }
              state.rechargeing = false;
            } else {
              state.rechargeing = false;
            }
          })
          .catch(function (error) {
            state.rechargeing = false;
            console.log(error);
            Toast.fail(_data.data.i18n.networkError);
          });
          //state.rechargeing = false;
      },
      popClose(){
        router.push({
          name: "rechargeHistoryList",
        });
      },
      cpfBtnClick(){
        if(state.cpfInput === ''){
          Toast.fail(_data.data.i18n.errInfoRequiredcrashHome);
          return
        }else {
          Toast.loading(_data.data.i18n.loading, {
            id: "loading",
            duration: 0,
            bgColor: "rgba(0, 0, 0, 0.9)",
            cover: true,
          });
          axios.post("/api/user/cpf/save", {
            cpf: state.cpfInput
          })
          .then(function (response) {
          //.then((response) => {
            if (response.data.code === 0) {
              Toast.hide("loading");
              state.cpfPopup = false
              state.needCpf = false
              methods.rechargeApply()
            } else if (response.data.code === 1004){
              Toast.hide("loading");
              Toast.fail(_data.data.i18n.INVALID_INPUT_VALUE);
            }else{
              state.cpfInput = '';
              Toast.hide("loading");
            }
          })
        }
      }
    };

    onMounted(() => {
      state.purpose = getLastTimeGameName('recharge')
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/fund/recharge/page", {})
        .then(function (response) {
          state.allowCustomAmount = response.data.data.allowCustomAmount
          state.availableAmount = response.data.data.fundAccount.availableAmount;
          state.plans = response.data.data.plans;
          state.myLv = response.data.data.vip.level;

          if(state.country2 === 'br') {
            state.needCpf = response.data.data.needCpf;
            state.boundCpf = response.data.data.boundCpf;
          }

          state.availableAmount2 = state.availableAmount <= 100 ? 0 : state.availableAmount -100
          for (let i = 0; i < response.data.data.plans.length; i++) {
            let j = response.data.data.plans[i];
            if (j.defaultPlan === true) {
              state.rechargeInputText = j.rechargeAmount;
            }
          }
          for(let v of response.data.data.othersInfo){	
            let text = v.mobile + ' ' + _data.data.i18n.successfully + ' ' + _data.data.i18n.rechargeBtnText+ ': ' + v.amount
            state.othersInfoList.push(text.toString())
          }
          state.othersInfo = state.othersInfoList
          setTimeout(() => {
            state.c2cRechargeOpen = response.data.data.c2cRechargeOpen
            var e = document.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            document.getElementById("radioaActive").dispatchEvent(e);
          }, 500);
          Toast.hide("loading");
        })
        .catch(function (error) {
          Toast.hide("loading");
          console.log(error);
        });
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>
<style scoped>

.pop-ab {
  padding: 0px !important;
  width: 100% !important;
  min-height: 100px;
  top: 50%;
  border-radius: 0px;
  background: transparent !important;  text-align: center;overflow-y: hidden !important;
}
.pop-ab .nut-button--round{height: 30px !important; margin-top:5px;}
.pop-ab .bd{ margin: 0; padding: 3px 0px !important; background: #000;}
.pop-ab img{ width: 100% !important; height: auto; margin: 0; max-width: 400px !important;}
.pop-ab .nut-swiper-inner{ height: auto !important; }

.f-24{color: #fd0;font-size: 24px; font-weight: 500; margin-left: 3px;}
.p-info{ line-height: 16px; position:absolute; right: 20px; top:0px; color:#940d01;}
.p-info .r{ float: right;}
.recharge-bd {
  min-height: 500px;
  margin-top: -5px;
  padding-top: 45px;
}
.top-text {
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #520150;
  margin: 0 20% 60px 20%;
  padding: 25px 0 10px 0;
}
.top-text strong {
  font-weight: 300;
  text-align: center;
  padding-top: 10px;
  opacity: .5;
}
.top-text .nut-countup {
  display: flex;
  align-items: center;
  width: auto !important;
  color: #ffdd00;
  font-size: 24px;
}
.recharge-ls {
  padding: 5px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.recharge-btn {
  width: calc(33% - 4px);
  margin: 3px 2px;
  /* background: #ffffe2 url(../assets/images/bg57.png) center center no-repeat;
  background-size:110% 100%; */
  background: linear-gradient(315deg,#3f8b93 0%,#253c48 30%,#287959 100%) !important;
  align-items: flex-start;
  text-align: center;
  border-radius: 5px;
  position: relative;
  border: 0;
  height: 60px;
}
.recharge-btn strong {
  line-height: 52px;
  font-size: 18px;
  font-weight: 400;
  color: #ffff9f;
}


.recharge-btn.active {
  background:#d4700e url(../assets/images/bg149b.png) center center no-repeat;
  background-size:450px auto;
}
.recharge-btn.active strong {
  color: #feff88;
  font-weight: 600;
}

.bt-tx {
  padding: 20px 10px 80px 20px; 
  text-align: left;
}
.bt-tx p {
  line-height: 16px;
  font-size: 10px;
  color: #999;
  margin: 0 0 5px 0;
  padding: 0;
}
.bt-tx p:first-child {text-align: center;}
</style>

<style>
.recharge-bd .nut-cell-group{ margin: 10px ;
  background: #014046 url(../assets/images/bg199.png) right -20px no-repeat;
    background-size: 100% auto; border-radius: 8px;
}
.recharge-bd .nut-cell-group__warp{ padding: 10px 10px 5px 10px;}
.top-text .nut-countup .run-number { font-weight: 500 !important;}
.nut-radio__icon {  color: #32044f !important;}
.pop-h200 p{ margin-bottom: 15px;}
.pop-h200 div{ text-align: center; opacity: .7;}
.pop-h200 div span{ padding-left: 28px; line-height: 30px; height: 30px; margin-right: 30px; color: #005611; font-size: 20px;
  background: url(../assets/images/bg77.png) left center no-repeat; background-size: auto 22px}
.pop-h200 div span:last-child{background: url(../assets/images/bg78.png) left center no-repeat; background-size: auto 22px;margin-right: 0px;}
.pop-h200 .nut-icon:before{ color: #fff;}
.radio-list { width: 100%;}
.radio-list .nut-radio{ margin-right: 0 !important;}
.radio-list .nut-radio--round{ display:inline-flex !important; width: 50%;}
.radio-list .nut-radio--round .nut-radio__label{ margin: 0 2px !important; vertical-align: top !important;}
.nut-form-item__label{width: 100px !important;}

.pop-cpf .cpf-input{font-size: 14px;padding: 5px; border: 2px solid #457b3d;height: 23px;border-radius: 5px;width: 200px; margin-right: 5px;}

.last-success .nut-radio__label{ background:url(../assets/images/bg512.png) right center no-repeat !important; 
background-size: 40px auto !important; padding-right: 43px;}

</style>
