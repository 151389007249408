<template>
<div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="titIconClick"
    :title="$t('home.cpf')"
    titIcon="home"
    fixed=true
    class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>



  <div class="login form-add">
    <div class="login-bn">&nbsp;</div>
    <div class="login-bd">
      <h3>{{$t('home.cpf_title')}}</h3>
      
      <br/>
      <div class="login-inp">
        <!-- <p class="fs10 fc-999">{{$t('home.cpf')}}</p> -->
        <login-input
          label=""
          :placeholder="$t('home.cpf')"
          type="text"
          rule="^.{1,64}$"
          maxLength="64"
          v-model="cpfInfo"
          @inputChange="(res) => (cpfInfo = res)"
        />
      </div>

      <p v-if="errTextShow" class="addcard-text" style="color: brown;">{{ errText }}</p>


      <div class="login-btn">
        <nut-button block type="info" @click="addCpf"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
      <p class="p-text">{{$t('home.cpf_text')}}</p>
    </div>
  </div>
</div>

</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import loginInput from "@/components/loginInput.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";

export default {
  components: {
    loginInput,
  },
  data() {
    return {
      country: '',
      cpfInfo:'',
      errTextShow: false,
      errText: this.$t('home.cpf_not'),
    };
  },
  mounted() {
    Toast.loading(this.$t('global.loading'), {
      id: "loading",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });
    axios({
      url: "/api/user/cpf/get",
      method: "get",
      params: {},
    }).then((res) => {
      if(res.data.data.cpf){
        this.cpfInfo = res.data.data.cpf
        this.errTextShow = false
      }else{
        this.errTextShow = true
      }
      Toast.hide("loading");
    })
    .catch(function (error) {
      Toast.hide("loading");
      console.log(error);
    });
  },
  methods: {
    addCpf() {
      this.errTextShow = false
      this.errText = ''
      if(this.cpfInfo === ''){
        this.errText = this.$t('global.errInfoRequired')
        this.errTextShow = true
        return
      }else {
        Toast.loading(this.$t('global.loading'), {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
        axios.post("/api/user/cpf/save", {
          cpf: this.cpfInfo
        })
        .then((response) => {
          if (response.data.code === 0) {
            setTimeout(() => {
              this.$router.push({ path: '/myHome' });
            }, 1500);
            Toast.hide("loading");
            Toast.success(this.$t('bankCard.success'));
          } else if (response.data.code === 1004){
            Toast.hide("loading");
            this.errText = this.$t('global.INVALID_INPUT_VALUE')
            Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
          }else{
            this.cpfInfo = '';
            Toast.hide("loading");
          }
        })
      }
    },
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      titIconClick(){
        router.push({
          name: "myHome",
        });
      },
    };
    return {
      ...methods,
      state,
    };
  },
};
</script>
<style scoped>


.addcard-text{ line-height: 16px; text-align: center; background: #fff5ed; padding: 10px; margin: 0 0 10px 0; color: #916d52; border-radius:5px;}
.c-red{color:rgb(243, 0, 0); margin-left: 5px;}
.login-inp{position: relative;}

.login-bd {
  padding: 10px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
}
.login-bd h3 {
  margin: 10px 0 10px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 5px 0 5px 0px;
}
.login-btn {
  margin: 30px 0 10px 0;
}
.login-bn {
  padding: 20px 20px 0px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}
.otpbtn {
  float: right;
}
.nut-input-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.w100 .input-text {
  width: 100% !important;
}
.p-text{ padding: 10px 0; margin: 0 !important; opacity: .5; color: #fff;}
</style>
<style>
.nut-input .input-text {
  padding: 0 0px 0 0px !important;
  width: 60% !important;
}
.pop-bankName {
  width: 98% !important;
  height: 520px;
  top: 50%;
  border-radius: 10px;
  background: #fff !important;  text-align: center;overflow-y: hidden !important;
}
.pop-bankName .bd{ margin: 10px;}

</style>