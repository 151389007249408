// EN
module.exports = {
  // 枚举
  filtersText:{
    wait_audit: 'Wait Audit',
    audit_accept: 'Audit Accept',
    audit_reject: 'Audit Reject',
    processing: 'Processing',
    success: 'Success',
    failed: 'Failed',
  },
  // 通用
  global:{
    language: 'Language',
    recharge: 'Recharge', //底部主导航 1
    invite: 'Invite',     //底部主导航 1
    reward: 'Quests',     //底部主导航 1
    my: 'My',             //底部主导航 1
    loading: "Loading...",                                    //加载中提示 1
    okText: "OK",                                             //ok按钮文字 1
    cancelText: "Cancel",                                     //取消按钮文字 1 
    safeDays1: 'Has been operating safely for',               //安全运营天数： safeDays1 +  ??? + safeDays2 1
    safeDays2: 'days',
    dataListInfo :'Only show data within 3 months!',          //只展示3个月数据  2  Only show the Last 3 Month Data
    noMore:'No more!',                                        //没有更多数据了
    noData:'No data!',                                        //没有更多数据了
    inputRequired:'Please fill in the required fields!',      //请填写必填项
    errInfoRequired:'This is required',                       //必填项错误提示
    mobileError: 'Wrong mobile number format!',               //电话号码错误提示
    passwordsError: 'Different passwords twice !',            //两次密码不一致
    agreeError: 'Please agree to the registration agreement', //请同意注册协议
    successfully_OPT: 'Successfully',
    successfully: 'Successfully',                        //短信验证码发送成功
    errorfully: 'Wrong mobile number!',                       //短信验证码发送失败
    networkError:'Network anomaly!',                          //网络错误
    eMail:'Feedback Email: ',                //提现底部提示1
    WithdrawalPartners:'Payment/Withdrawal Partners Below.',  //提现底部提示2
    submittedSuccess:'Submitted successfully!',               //提交成功
    confirm:'Confirm',                                        //确定按钮
    submit:'Submit',                                          //提交按钮
    save:'Save',                                              //保存按钮
    view:'view',                                              //查看按钮
    downloadApp: 'Download App',                              //下载app
    addDesktop: 'Add to desktop',                             //添加到桌面
    addDesktopTitle: 'For your convenience, it is recommended to add our page to the desktop',  //为了你更方便的使用，建议把我们添加到桌面
    addDesktopText1a: '1、Click',               //1、点击 xx 图标   (第一步)
    addDesktopText1b: 'icon',                  //1、点击 xx 图标
    addDesktopText2: '2、Select "Add to desktop" ',   //  (第二步)选择 "添加到主屏幕"
    rechargeBtnText:'Recharge',  //充值按钮
    withdrawalBtnText:'Withdrawal',  //提现按钮
    inviteBtnText:'invite',  //邀请按钮
    slogan1: '100% Secure&Real, No cheating!',
    Today: 'Today',
    Week: '1 Week',
    Month1: '1 Month',
    Month3: '3 Month',
    Your_invitation_link_has_been_copied: 'Your invitation link has been copied!',
    hotGame: 'Hot Game',
    newGame: 'New Game',
    top10: 'Player Top 10',
    NON_VIP_CAN_ONLY_INPUT: 'Non vip can only input',
    INVALID_INPUT_VALUE: 'Invalid input value.',
    REQUEST_FAILED_PLEASE_TRY_LATER_OR_CONTACT_US: 'Request failed. Please try later or contact us.',
    SERVER_ERROR: 'Server error. Please try later or contact us.',
    SIGN_UP_BONUS_1: 'Sign Up To Get ', //Sign Up To Get ** Bonus >>
    SIGN_UP_BONUS_2: ' Bonus >>',
    SIGN_UP_BONUS_3: 'Register to get a discount coupon ',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    
    RECEBER_CUPOM_DE_DESCONTO_DE: 'Receive discount coupon ',
    OBTER_CUPOM: 'Get coupon',
    DISCOUNT_COUPON: 'Discount coupon',
    VALID: 'Valid',
    COUPON: 'Coupon',
    EXPIRED: 'Expired',
    USAR: 'Use',
    FUN_MODE: 'Fun Mode',
    RECIBO: 'Receipt',
    PAY: 'Pay',
    FUN_MODE_i: 'Fun mode amount is virtual amount, choose real mode to start real game experience',

    VIP_BONUS_1: 'This Bonus can only be claimed by VIP',
    VIP_BONUS_2: '+, please upgrade your VIP level to claim it!',
    DEMO_END_TEXT: 'Your opportunity to experience today has been used, please go to the real mode to start real winning.',
    TO_REAL_MODE: 'To real mode',
    Prediction_Channel: 'Prediction Channel',
    Prediction_Channel_Text: 'The prediction channel is only open to VIP1+ users, please upgrade your VIP level.',
    Got_It: 'Got It',
    Upgrade: 'Upgrade',
    Recharge_Dialog_Title: 'Balance not enough',
    Recharge_Dialog_Text: 'You can recharge or enter fun mode to experience the game',
    Recharge_Dialog_Btn: 'Play',

    Received:'Received',
    Total:'Total',
    Lucky_Lifafa:'Lucky Lifafa',
    Easy_share_Earn_Rupees:'Easy share, Earn Rupees',
    Remaining_today: 'Remaining today',
    pieces:'pieces',
    Play_now:'Play now',

    DOWNLOAD_APP: 'Download APP',
    DOWNLOAD_APP_TEXT:'For a better game experience, get rewards faster and get valuable information',
    DOWNLOAD_APP_TEXT2: 'Download the app now to claim ',
    DOWNLOAD_APP_TEXT3: 'bonus!',
    DOWNLOAD_APP_TEXT4: 'Please go to APP to receive reward!',
    DOWNLOAD_APP_BTN: 'Download',
    DOWNLOAD_APP_CANCEL: 'Cancel',
    Through_Email1: 'If there is any problem, please contact us through Email:support',
    Through_Email2: 'inwin99.com, working hours: 7:00-20:00',

    downloadPopup_h3: 'New update available',
    downloadPopup_p: "Please download the latest app from Google Play.",
    downloadPopup_later: 'Update later'
    
  },
  // 游戏页
  game:{
    h1: 'Crash',     // 2
    tips: 'Tips',
    gamePaused: 'Game Paused', // 2
    paused: 'Paused', // 2
    playTime: 'Play Time', // 第二个字母大写
    gameWillStartingIn: 'Game will starting in',
    youWin: 'You Win',
    receiveBonus: 'Receive Bonus',
    nextGame: 'Next Round',  // 第二个字母大写  next game 改成 next round
    all: 'ALL',
    start: 'Start',
    stop: 'Stop',
    autoStop: 'Auto Stop',  //第二个字母大写
    myBet: 'My order',  // mybet 改成 My Order
    players: 'Players',    // 大写 Players
    myHistory: 'My Order',  //改成 my order
    winRatio: 'Stop',   
    winAmount: 'Win',
    betAmount: 'Input',
    last20: 'Only show the last 20 players who joined!',
    stopPlayer: 'Stop Player',
    bet: 'bet',
    status: 'status',
    ratio: 'Stop',
    feeAmount: 'Time',
    escape: 'Escape',
    more: 'Only show orders today, click to view more',
    noHistory: 'No bet history',
    historData:'History game data',
    notEnough: 'your wallet amount not enough',
    pleaseWithdraw: 'For your money safety, please withdraw.',
    //pleaseWithdraw2:'Congratulations for win! For your money safety, please withdraw.',
    pleaseWithdraw1:'Congratulations!',
    pleaseWithdraw2:'You have made more than ',
    // pleaseWithdraw_200:'200',
    pleaseWithdraw3:'before continuing to play,',
    pleaseWithdraw4:'please withdraw to ensure ',
    pleaseWithdraw5:'that you can successfully receive money.',
    go: 'GO!',
    gotitBtn: 'Got it',
    info1: 'Invite one friend',  // You can invite to fill up to xxxx
    info2: 'Regain starting bonus to ',  // You can invite to fill up to xxxx
    waitForTheNextRound: 'Wait for the next round!',
    info3: 'Vip0 input amount can only be ',
    DialogTitle: 'Congratulations for big win!',
    DialogContent1: 'For vip',
    DialogContent2: ', your max stop ratio is ',
    DialogContent3: 'x, you can level-up vip to unlock the limit.',
  },
  
  // 红绿游戏页
  PARITY:{
    H1: 'PARITY',
    NAME1: 'Jathan',
    NAME2: 'Deniz',
    NAME3: 'Elvin',
    PLEASE_WAIT: 'Please wait...',
    ROUND: 'Round',
    NEXT_ROUND_PREDICTION_STSRT_AT: 'Next round prediction start at',
    THIS_PREDICTION_STARTS_AT: 'This prediction starts at',
    LIVE_PREDICTION: 'LIVE Prediction',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_1: 'LIVE Prediction is now available in ',  //1 LIVE Prediction is now available in Prediction room!
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_2: 'Prediction ', // 2
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_3: 'room!',       // 3
    MY_CHOICE: 'My Choice',
    COUNT_DOWN: 'Count Down',
    SELECT_TIME: 'Select Time',
    RESULT_TIME2: 'Result count down',
    PAUSED_TIME: 'Paused',
    MY_POINT: 'My Point',
    VIP: 'Vip',
    JOIN_GREEN: 'Join Green',
    JOIN_VIOLET: 'Join Violet',
    JOIN_RED: 'Join Red',
    JOIN_NUMBER: 'Join Number',
    RECORD: 'Record',
    CONTINUOUS: 'Continuous',
    PROBABILITY: 'Probability',
    NEXT_PAGE: 'Next page',
    UPGRADE: 'Upgrade',
    EVERYONE_ORDER: "Everyone's Order",
    MY_ORDER: 'My Order',
    BALANCE: 'Balance',
    FEE: 'Fee',
    JOIN: 'Join',
    CONTRACT_POINT: 'Contract Point',
    ALL: 'All',
    NUMBER: 'Number',
    VIP1_INPUT_FREELY: 'Vip1+ input freely',
    VIP0_ROOKIE_GUIDE: 'Vip0 Rookie Guide',
    FOLLOW_ADVICE_AND_YOU_WIN_FINALLY: "Follow advice and you'll win finally!",
    INPUT: 'Input',
    LOSE: 'FAIL',
    WIN: 'WIN',
    SO_THIS_ROUND_SHOULD_INPUT: 'So this round should input',
    CONFIRM: 'Confirm',
    TOTAL_PROFIT: 'Total Profit',
    PROFIT: 'Profit',
    USER: 'User',
    SELECT: 'Choose',
    POINT: 'Input',
    PERIOD: 'Period',
    RESULT: 'Result',
    AMOUNT: 'Win',
    RESULT_TIME:'Time',
    BTC_PRICE: 'BTC price',
    SERIAL_NUM: 'Serial Num',
    NEW: 'NEW',
    SUGGEST: 'Suggest',
    PREDICT: 'Predict',
    SERIAL: 'Serial',
    PROFIT_WIN_INPUT :'Profit (Win-Input)',
    RESULTS_ORIGIN: 'Results Origin',
    PREDICTION_GAMEPLAY: 'Prediction Gameplay',
    INVESTMENT_SKILLS: 'Investment Skills',
    ANALYST_INTRODUCTION: 'Analyst Introduction',
    RESULTS_ORIGIN_TEXT_1: 'Results come from the last digit of the composite index of Coinbase Top 10 digital currencies.',
    PREDICTION_GAMEPLAY_TEXT_1: 'The platform provides prediction results calculated by cooperative analysts 7 times a day, 7-15 rounds per prediction, for reference.',
    PREDICTION_GAMEPLAY_TEXT_2: 'Predicted opening time:',
    PREDICTION_GAMEPLAY_TEXT_3: 'Efficient invest strategy combined with analyst predictions can increase the odds of winning in the long run.',
    PREDICTION_GAMEPLAY_TEXT_4: '(The prediction results are for reference only, accuracy is not guaranteed)',
    INVESTMENT_SKILLS_TEXT_1: 'Both wining rate and investing strategy are important.',
    INVESTMENT_SKILLS_TEXT_2: '1.Investing strategy',
    INVESTMENT_SKILLS_TEXT_3: 'Simple investing strategy【double bet】:If you lose this round,for next round, double the amount you invest until you win.',
    INVESTMENT_SKILLS_TEXT_4: 'For example(3 times double bet)',
    INVESTMENT_SKILLS_TEXT_5: '2.Follow and Opposite',
    INVESTMENT_SKILLS_TEXT_6: 'Learn to observe "continuous", when the same result appears in a row,choose the opposite result, and the winning rate is often higher.',
    INVESTMENT_SKILLS_TEXT_7: 'More tips on official telegram channel>>',
    INITIAL_BALANCE: 'Initial Balance',
    INVESTMENT_PLAN: 'Investment Plan',
    TOTAL_ASSETS: 'Total assets',
    ANALYST_INTRODUCTION_1: 'Graduated from the University of Nigeria, focusing on the practice of mathematical theory probability methods of trading, working in the field of data analysis on Wall Street for more than 8 years.',
    ANALYST_INTRODUCTION_2: 'Master of Financial Mathematics from MIT, specializing in price analysis, with more than 5 years of experience in blockchain investment.',
    ANALYST_INTRODUCTION_3: 'BA in Financial Economics from Oxford University, investment expert, specializing in historical data analysis, achieved 650% return on investment in three months.',
    CONGRSTS_XXX_WIN_XXX_1: 'Congrats ',   // Congrats 900***0000 Win 8888
    CONGRSTS_XXX_WIN_XXX_2: ' win ',
    GAME_WILL_COMING_SOON: 'Game will coming soon!',
    ROOM_NOT_EXISTS: 'Room not exists!',
    PLEASE_CONFIRM_YOUR_INPUT: 'Please confirm your input!',
    AS_A_ROOKIE_YOU_CAN_ONLY_INPUT: 'As a rookie, you can only input ',
    ONLY_FOR_VIP1: 'Only for VIP1+',
    ONLY_FOR_VIP2: 'Only for VIP2+',
    CONNECTING: 'connecting',
    OOPS_PLEASE_CHECK_YOUR_NETWORK: 'Oops! Please check your network',
    TRY_AGAIN:'Try again',
    BTC_PRICE_BY_BINANCE: 'BTC price by Binance',
  },
  //转盘
  WHEEL:{
    H1: 'WHEEL',
    HISTORY: 'History',
    BET_TIME: 'Choose time',
    PLAYING:'Playing',
    PLEASE_WAIT: 'Please wait',
    CLICK_TO_BET: 'Click to choose',
    VIP_ONLY: 'VIP1+ Only',
    RULE_H1: 'Wheel Rule',
    RULE_T1: '1.How to play?',
    RULE_T2: 'Players can select the color of the turntable,Select the bet amount first, then the color.',
    RULE_T3: '2.Win',
    RULE_T4: 'The odds of blue and yellow are 2X, bet 100, win 200.',
    RULE_T5: 'The odds of red are 9X, bet 100, win 900.',
  },
  // 挖雷游戏页
  MINE:{
    H1: 'MINE',
    ROW:'Row',
    START: 'Start',
    BONUS: 'Bonus',
    NEXT: 'Next',
    STOP: 'Stop',
    POINT: 'Point',
    SELECT: 'Select',
    WIN_AMOUNT: 'Win amount',
    ORDER_NUM: 'Order num',
    STOP_AND_GET_BONUS: 'Stop and get bonus!',
    CHOOSE_GAME_AMOUNT: 'Choose game amount',
  },
  // 登录页
  userLogin: {
    signIn: 'Sign In',                       //登录标题
    smsSignIn: 'SMS Login',                  //SMS登录标题
    mobileNumber: 'Mobile Number',           //手机输入框标题
    mobileNumberPlaceholder:'Mobile Number', //手机输入框默认提示
    password: 'Password',                    //密码输入框标题
    passwordPlaceholder: '6~32 length',      //密码输入框默认提示
    login: 'Login',                          //确认登录 按钮
    loginSuccess: 'Login success!',          //登录成功 文字提示
    forgotPassword: 'Forgot password?',      //忘记密码 链接
    register: 'Register',                    //注册连接 链接
    SMSLogin: 'SMS verification login',      //短信登录 链接
    passwordLogin: 'Password Login',          //密码登录 链接
    signIn_text:'Already registered? ',
    signIn_btn: 'Login'
  },
  // 设置密码页
  pesetPassword: {
    h1:'Reset Password',              //页面标题
    old:'Old Password',               //旧密码输入框    1 
    oldPlaceholder:'Old Password',    //旧密码输入框 提示   1
    new:'New Password',               //新密码输入框   1
    newPlaceholder:'New Password',    //旧密码输入框 提示 1
    confirmPassword: 'Confirm Password',
    confirmPasswordPlaceholder: 'Confirm Password',
    success: 'Set password success!', //设置成功提示  1
  },
  // 找回密码
  resetPassword:{
    h1:'Reset Password',
    newPassword: 'New password',
    newPasswordPlaceholder: '6~32 digit',
    confirmPassword:'Confirm Password',
    confirmPasswordPlaceholder:'Confirm Password',
    success: 'Password reset success!'
  },
  // 用户注册
  userRegister:{
    h1:'Register',                                  //页面大标题
    registerTopText1: 'The Gov. certified online platform.  Your account and funds is regulated by Bank. You can withdraw anytime.',  //注册顶部宣传文案
    registerTopText2: 'New register bonus',         //注册顶部宣传文案 第2段： New register bonus xxx
    registerText3: 'Business Email:',
    registerText4: 'Service Email:',
    verificationCode: 'Verification Code',          //验证码输入框标题
    verificationCodePlaceholder: '6 digits',        //验证码输入框默认提示
    OTP: 'OTP',                                     //发送验证码 按钮
    confirmPassword: 'Confirm Password',            //确认密码输入框 标题
    confirmPasswordPlaceholder: 'Confirm Password', //确认密码输入框默认提示
    recCode: 'Recommendation Code',  //邀请码输入框
    recCodePlaceholder: 'Not required',      //邀请码输入框 默认提示
    iAgree: 'I agree',                              //同意注册协议 文案
    privacyPolicy: 'Privacy Policy',                //同意注册协议 链接
    registerBtn: 'Register',                        //确认注册 按钮
    success:'Registration success!',                //注册成功 弹窗 文案
    signUpBonus:'Starting Bonus!',                   //注册成功 弹窗 文案、
    signUpCoupon:'Received discount coupon',          //收到优惠券
  },
  
  //我的主页
  home: {
    h1: 'My Home',            //页面大标题
    mobile: 'Mobile',         // 改成 phone
    vip: 'VIP',               //
    inviteCode: 'InviteCode', //
    balance:'Balance',        //
    recharge:'Recharge',      //
    withdrawal:'Withdrawal',  //
    vipDescription: 'VIP Description',
    link:'Official Channel',      // Official Telegram
    link_r: 'Daily Bouns',
    promotion: 'Promotion',            //
    envelopesCenter:'Envelopes Center',//
    transactions:'Transactions',       //
    bankCard:'Bank Card',            // 
    resetPassword:'Reset Password',    //
    cs:'Complaints & Suggestions',     //
    about:'About',                     //
    logout:'Logout',          //退出登陆

    vipTitle: 'VIP Privileges Description',   //
    withdrawakQuoat: 'Withdrawak Quota',      //
    level: 'VIP Level',                       //
    totalRecharge: 'Total Recharge',          //
    profitRatio: 'Profit Ratio',              //
    withdrawalServices: 'Withdrawal Service',//
    vipRewards: 'VIP Rewards',                //

    dt: 'Description of withdrawal Amount:',  //
    dd1: '1、Total Withdrawal = Total Recharge x Profit Ratio;',  //
    dd2: 'VIP8 Withdrawal are not subject to recharge restrictions,so you can withdraw as much as you earn.',  //

    gameAmount: 'Game account balance comes from platform profit and recharge.',
    agentAmount: 'Agent account balance comes from the agent commission system'
  },
  // 设置头像
  setAvatar: {
    h1:'Set Avatar',                  //页面大标题
    current:'Current avatar',         //当前头像
    avatars:'Avatars to choose from:',//选择头像
    success:'Avatar replaced successfully!' //成功提示
  },
  //银行卡
  bankCard: {
    h1: 'Bank Card',
    noBankcCard: 'No Bank Card is currently bound!',
    addBankcCard: 'Add bank card',
    delete: 'Delete or not?',
    editTitle: 'Edit bank card',      //编辑银行卡
    addTitle: 'Add Bank Card',        //添加银行卡
    realName: 'Real name',            //输入框字段
    realNamePlaceholder: 'Real name(English)', //输入框字段 提示
    ifscCode: 'IFSC Code',            //输入框字段
    ifscCodePlaceholder: 'IFSC Code', //输入框字段 提示
    bankName: 'Bank Name',            //输入框字段
    bankNamePlaceholder: 'Bank Name', //输入框字段 提示
    bankAccount: 'Bank Account',      //输入框字段
    bankAccountPlaceholder: 'Bank Account',//输入框字段 提示
    upiVpa: 'UPI ID (Vpa)',                //输入框字段
    upiVpaPlaceholder: 'UPI ID (Vpa)',     //输入框字段 提示
    email: 'E-mail',                   //输入框字段
    emailPlaceholder: 'Email',        //输入框字段 提示
    success:'Success',                //成功提示
    addcard_text:'Please make sure that the information is correct, avoid not being able to receive withdrawals.',
    GCash: 'GCash', 
    GCashPlaceholder: 'GCash Mobile', 
  },
  //投诉 & 建议
   CS: {
    h1: 'Complaints Suggestions',
    noData: 'No complaints and suggestions!',
    addBtn: 'Add complaints',
    waitReply: 'Wait Reply',
    replied: 'Replied',
    h2: 'Write complaints or suggestions',
    type: 'Type',
    typePlaceholder: 'Select type',
    title: 'Title',
    titlePlaceholder:'Please enter title!',
    description: 'Description',
    descriptionPlaceholder: 'Please enter description',
    servicTime: 'Service:10:00~16:00, Mon~Fri',
    servicTime2: 'Please provide screenshots of your problem、Upi/UTR number and your AFRSTAR id,send to our customer service email: support@afrstar.com',
    recharge: 'Recharge',
    withdraw: 'Withdraw',
    invite: 'Invite',
    gameCrash: 'Game Crash',
    others: 'Others',
  },
  // 消息
  envelopes: {
    h1:'Envelopes Center',  //页面大标题
    new: 'New',             //新消息标识
  },
  // 充值页
  recharge: {
    h1:'Recharge',               //页面大标题
    balance: 'Balance',          //标题
    amount: 'Amount',            //金额title
    amountPlaceholder: 'Amount', //金额输入框提示
    receipt: 'Receipt',          //手续费title
    receiptPlaceholder: 'Amount',//手续费输入框提示
    channel: 'Channel',          //渠道title
    success: 'Recharge success!',//充值成功
    failSelect: 'Please select a recharge channel!',         //请选择充值渠道！
    failEnter: 'Please enter the correct amount!',           //请输入正确的金额
    pop:'Complete the payment on the new page, please wait...',             //请在新页面完成支付
    description:'Description',
    tx5: '1.If there is any problem with the recharge, please contact us through ',
    tx5_1: 'support Telegram',
    tx5_2: ' , working hours: 7:00-20:00',
    tx6: '2.The recharge amount will increase your VIP level, and a higher VIP level will increase your Withdrawal Amount, Daily Bonus, etc. ',
    tx7: 'See the',
    tx8: '“VIP Description”',
    tx9: '3.Do not reveal your password to prevent it from being stolen. Our staff will not ask for your password.',
    levelUpVip: 'Upgrade Now!',
    popTitle_1:'VIP Description',
    popTitle_2:'VIP Description',
    
  },
  // 充值列表页
  rechargeList: {
    h1:'Recharge History',         //页面大标题
    giftAmount: 'Gift Amount',     //列表字段
    applyAmount: 'Apply Amount',   //列表字段
  },
  // 提现页
  withdrawal: {
    h1:'Withdrawal',                       //页面大标题
    availableBalance:'Available Balance',  //可用余额
    agentAmount: 'Agent account',           //邀请金额
    gameAccount: 'Game account',           //游戏金额
    transfer: 'Transfer',
    withdrawAmount: 'Withdraw amount',     //提现金额 输入框标题
    handlingFee: 'Handling fee',           //手续费 输入框标题
    receiptAmount: 'Receipt amount',       //收款金额 输入框标题
    bankCard: 'Bank Card',                 //银行卡选择 标题
    withdrawalBtn: 'Withdrawal',           //提交 按钮
    errInfoBankCard: 'Please add a bank card to withdraw.', //请先添加银行卡 提示信息
    errInfoRecharge: 'You need recharge more to withdraw.', //请先充值才能提现 提示信息
    errInfopPlayMore: 'Dear Sir/Madam,Your input amount is too few, please keep playing.',    //请先玩更多才能提现 提示信息
    description:'Description',
    tx4: '1.If there is any problem with the recharge, please contact us through ',
    tx4b: 'If there is any problem with the recharge, please contact us through ',
    tx5: '1.If there is any problem with the withdrawal, please contact us through ',
    tx5b: 'If there is any problem with the withdrawal, please contact us through ',
    tx_contact_us:'contact us',
    tx5_1: 'support Telegram',
    tx5_2: ' , working hours: 7:00-20:00',
    tx6: '2.Higher VIP level can increase the withdrawal limit.',
    tx7: 'See the',
    tx8: '“VIP Description”',
    tx9: '3.In order to prevent money laundering, you must reach enough amount of input before you can withdraw.',
    tx10: 'Congratulations!',
    tx11: '🎉🎉🎉Your account security has been verified🤝🤝🤝Now you can continue to play!🥳🥳🥳',
    btntx: 'Play',
    popBankList: 'This bank card is not supported at the moment, the following is a list of available banks!'
  },

  // 提现列表页
  withdrawalList: {
    h1:'Withdrawal History',            //页面大标题
    receiptAmount: 'Receipt Amount',    //列表字段
    applyAmount: 'Apply Amount',        //列表字段
    withdrawFee: 'Withdraw Fee',        //列表字段
    pleaseCheckBank: 'Please confirm that your bank information is correct and withdraw again',    //列表字段
  },
  // 金币转换
  transfer: {
    h1:'Transfer',
    transferAmount: 'Transfer Amount',
    btntx: "Confirm",
    success: 'Transfer success',
    tx1: 'Game account balance comes from platform profit and recharge.Agent account balance comes from the agent commission system.',
    tx2: 'If you need it, the agent account balance can be freely transferred to the game account balance to play game.Both two account balances can be withdrawn.',
  },
  //钱包明细列表
  transactions:{
    h1:'Transactions',                   //页面大标题
    cashH1:'Cash Transactions',
  },
  //任务页
  taskReward:{
    h1: 'Quests',                   //页面大标题
    day: 'Day',                          //新手任务   xx day
    checkIn:'Check-in',
    checkInLocked:'Locked',
    toTask: 'Go',                //去做任务按钮
    dailyQuest: 'Daily Quest',
    dailyQuestLocked: 'Daily Quest (Locked)',
    dailyQuestCompleted: 'Daily Quest (Completed!)',
    dailyQuestFinished: 'Daily Quest (Finished)',
    completeTask: 'Go',//完成任务
    receiveReward: 'Receive Reward',       //领取奖励按钮
    received: 'Received',                //已经领取按钮
    completed: 'Completed',              //按钮
    complete: 'Complete',                //完成百分比    100% Complete
    checkIn7Text: 'Check-in for 7 consecutive days',      //签到进度标题  Check in for 7 consecutive days (x/x)
    vipCheckInText: 'Upgrade VIP to get more bonus',//签到进度副标题
    checkInText: 'Daily check-in to receive rewards',    //7天之后的 签到标题
    missionTarget: 'Quests',     //任务列表标题
    getBn: 'Received Reward!',              //领取弹框 大标题
  },
  // 代理激励计划
  agentGrowth: {
    h1: 'Agent Growth',
    bn1: "The Agent crore cash growth plan is divided in ",   //bn1 + xx + bn2 + xx + bn3!
    bn2: " levels, and each level has generous rewards. Complete the highest level and get a reward of ",
    bn3: " immediately.",
    target:'target',
    h4_1: 'Reward conditions',
    h4_item1_1: 'Invite',
    h4_item1_2: 'effective person(register and recharge), you can get cash.',
    h4_2: 'Upgrade skills',
    h4_item2_1: 'You share the link and someone else register through the link, that person will be your subordinate. Once ',     
    h4_item2_2: ' subordinates become effective, you can get and withdraw ',   
    h4_item2_3: ' immediately.',
  },
  // 代理激励计划
  AGENT_SUPPORT_PLAN: {
    H1: 'Agent Support Plan',
    MY_SUPPORT_PLAN: 'My Support Plan',
    MY_SUPPORT_PLAN_LEVEL: 'My Level',
    TODAY_EFFECIVE_USERS_AMOUNT: 'Today Effecive Users Count',
    TODAY_POINTS_BONUS: 'Today Input Bonus',
    YESTERDAY_SUPPORT_REWARD: 'Yesterday Support Reward',
    BTN_TEXT: 'Contact customer service to receive >>',
    INFO_TEXT: 'If the system detects any cheating, bonuses and accounts will be frozen.',
    AGENT_REWAED_TOP: 'Agent Reward top 5',
    LEVEL: 'Level',
    LV: 'Lv',
    PHONE: 'Phone',
    ORIGINAL_SYSTEM_REWAED: 'Original System Reward',
    SUPPORT_REWARD: 'Support Reward',
    ONLY_TOP_5: 'Only show Top 5 data!',
    DAILY_REQUIRE: 'Daily Require',
    REWARD: 'Reward',
    EFFECIVE_USERS_AMOUNT: 'Effecive Users Count',
    POINTS_BONUS: 'Input Bonus',
    TOTAL_SUPPORT_REWARD: 'Total Support Reward',
    CONSECUTIVE_DAYS_EXTRA_REWAED: '7 Consecutive Days Extra Reward',
    I_E_Q: '(Invite + Effective + Quests)Bonus'
  },	
  // 代理激励计划
  invite: {
    h1: 'Invite',
    agentAmount: "Agent Amount",
    withdraw:'Withdraw',
    totalPeople: 'Total Count',
    effectiveNum: 'Effective Count',
    h3: 'Crore Agent Growth Plan',
    contribution: 'Contribution bonus',
    more: 'swipe to see more',                                           //更多提示
    affinity: 'Grade',                                                //table 字段
    inviteCount: 'Invite Count',                                         //table 字段
    effectiveCount: 'Effective Count',                                   //table 字段
    effectiveBonus: 'Effective Bonus',                                   //table 字段
    inviteCommissionAmount: 'Invite Bonus',                  //table 字段
    subBetCommissionRatio:'Input Bonus',                    //table 字段
    subBetCommissionAmount:'Input Bonus',                  //table 字段
    subFirstRechargeCommissionRatio:'Sub First Recharge CommissionRatio',//table 字段
    subFirstRechargeCommissionAmount:'Sub First Recharge Commission Amount',//table 字段
    totalCommissionAmount:'Total Bonus',                     //table 字段
    Grade: 'Grade',
    Bonuss: 'Bonus',
    rules: 'Rules',                 //tab标题1
    bonus: 'Bonus',                 //tab标题2
    myCode: 'My Promotion Code',    //tab 右侧链接标题
    you: 'YOU',
    youTitle: '4-level agency system, each level bring commission!',
    level: 'Grade',              // 等级title
    label1: 'Bonus',     // 第1个字段名称

    rulesTitle1: 'The easiest',         // 规则1标题
    rulesText11: 'Reward 1~99 for each registered user,get income immediately!', // 规则1内容
    rulesText12: 'If you invite 100 users, earn at least 400 and possibly up to 9900！',

    rulesTitle2: 'The longest',         // 规则2标题
    rulesText21: 'Four-level invitees,each user will generate commission!', // 规则2内容
    rulesText22: 'If each person invites 10 people, each person only needs to bet 100 per day, after 30 days, through the four-level agency system, you will earn more than 35,000!', // 规则2内容

    rulesTitle3: 'The most effective',         // 规则3标题
    rulesText31: 'Click and share bonus link in official telegram channel, make money at your fingertips！', // 规则3内容
    rulesText32: 'Our agent Zuma is a street vendor,he invite 100 new users to sign up every day by sharing the link,he probably earn 1500 everyday!If he can, so can you!',

    rulesTitle4: 'What is Recall?',
    rulesText41: 'If a user has not visited AFRSTAR for 21 days, and revisit through your invite link or forward lifafa link, he will be regarded as your new invited user. Keep promoting AFRSTAR and you will keep getting new users and commissions.',

    h4: 'My Promotion Code',
    h4Title: 'The hottest online making money platform in 2024.Easily earn 30,000~200,000',
    btn: 'Copy link',
    to: 'or share to',
    shareMakeMoneya: 'Share make money',
    info1: 'Every user you invite will bring you a bonus of MAX 99,and those you invite will get ',
    info2: ' rewards.',

    //ShareNetwork
    url: 'https://AFRSTAR.com',        //分享链接
    title: 'AFRSTAR The Safest Earning Platform (https://afrstar.com)',      //分享标题
    description: 'Quick and Easy Ways to Make Money in 2024 Starting Bonus🚀Withdraw In 3m🚀100X Win🚀 10 Million Share Reward🚀100% Safe🚀 The sooner you join, the more you earn!',//分享描述
    quote: 'The sooner you join, the more you earn!',  
    hashtags: 'game,crash,AFRSTAR',  //分享关键词
    twitterUser: 'AFRSTAR',     //作者
    cashHistory:'Cash history',
    infoToast: 'Invited users must meet the effective number standard required by the system, otherwise they will not be counted. Invite more effective users, and the statistics will resume.',

    Congratulations:'Congratulations',
    NewAgencyIncome:'Recent Agency Income',
    NewInvites:'Recent Invites',
    NewEffective:'Recent Effective',
    EarnMoreNow:'Earn More Now',
    Last30Days: 'The data comes from the last 30 days',
  },
  //我的历史下注
  myPlayList: {
    h1: 'My Order',      //页面大标题
    bet: 'Input: ',
    win: 'Win: ',
    crash: 'Crash',
    ratio: 'Stop',
    originWinAmount: 'Win: ',
    feeAmount: 'Fee: ',
    gameNum: '',
    time:'Time'
  },
  //领取红包页面
  receiveBonus:{
    h1: 'Receive Bonus',              //页面大标题
    name: 'Your friend',              // xxxx  （没有手机号的时候会显示这个字段）
    h2: 'sends you a bonus !',        // xxxx 给你发来了红包！
    h3: 'has send',
    btn: 'Receive',               //按钮
    btn2: 'Get Latest Bonus', //按钮
    success: 'Receive success!',      //成功提示
    user: 'User',                     // 用户 xxxx 领取了红包  前
    receives: 'receives the reward !',// 用户 xxxx 领取了红包  后
    // errText: 'Please click button to get the latest share bonus!',
    errText: '',
  },
  //分享红包页面
  shareBonus:{
    h1: 'Share Bonus',                         //页面大标题
    h2: 'Receive bonus successfully!', //页面标题
    h3: 'Share to others will bring you 100% return!',              //副标题
    btn: 'Share to make money',                 //按钮
    copied: 'Your bonus link copied!',//复制地址之后提示文字
  },
  //小提示
  tips:{
    tips1: 'Min input 10,Max input 10000',
    tips2: 'Get money reward by completing tasks every day!',
    tips3: 'Rockets can fly up to X100！',
    tips4: 'Finding patterns is the key to winning！',
    tips5: 'Murphy is the first skydiver to successfully win 85.77 times, pay tribute to him!',
    tips6: 'Continuous crash at low, the chance of flying much higher in the next round will increase！'
             
  },
  //vip说明
  VIP_PRIVILEGE: {
    VIP_PRIVILEGE: 'VIP Privilege',
    WITHDRAWAL_DESCRIPTION: 'Withdrawal Description',
    LEVEL: 'Level',
    TOTAL_RECHARGE: 'Total Recharge',
    WITHDRAWAL_QUOTA: 'Withdrawal Quota',
    REWARDS: 'Rewards',
    DAILY_BONUS: 'Daily Bonus',
    EXTRA: 'Extra',
    WITHDRAWAL: 'Withdrawal',
    STOP_LIMIT: 'Stop Limit',
    INPUT_LIMIT: 'Input Limit',
    DATA_ANALYSIS: 'Data Analysis',
    ROOM_LIMIT:'Room Limit',
    MAX: 'Max',
    RECORD: 'Record',
    CONTINUOUS: 'Continuous',
    PROBABILITY: 'Probability',
    TEXT1: 'Withdrawable amount(Lv1-Lv4)=Balance - Starting bonus(',
    TEXT2: ') Upgrade to VIP6 to withdraw freely!the withdrawal limit up to 199999!',
    TEXT3: 'Upgrade to the corresponding VIP level to complete this withdrawal',

    
  },
  //游戏规则 飞机
  gameRules:{
    h1: 'Play Rules',
    dt1: 'How to play ?',
    dd1_1: 'First, choose how much you want to place order. Click "START" to place your order and watch the multiplier rise from 1.00x upwards! ',
    dd1_2: 'You can click "STOP" at any time in order to multiply your point with the current multiplier. But be careful: The game can end at any time, and if you have not stop by then, you get nothing! ',
    dd1_3: 'You need to wait for the next game start.',
    dt2: 'How is the amount calculated ?',
    dd2_1: 'If the amount of your order is 100, the escape multiple is 10.0x, after deducting 3 service fee, your contract amount is 97',
    dd2_2: '10.0X: You will get (97 * 10) 970.',
    dt3: 'How high can the multiplier go ?',
    dd3: 'Up to 100 times',
    dt4: 'What is auto stop ?',
    dd4: 'If you choose to auto stop, when the multiple reaches the selected multiple, it will auto stop and get rewards.',
    dt5: 'Why are the multipliers in different colors ? ',
    btn: 'I got it',
  },
  //游戏规则 
  gameRulesParity:{
    h1: 'Play Rules',
    dt1: 'Emerd',
    dd1_1: 'A round of 30 seconds, 25 seconds to place an order, 5 seconds to announce the result, 24 hours non-stop,a total of 2880 rounds a day.',
    dd1_2: 'Input 100 in a round',
    dd1_3: 'Join Green:',
    dd1_4: 'If the result is 1,3,7,9, you will get 195, if the result is 5, you will get 142.5.',
    dd1_5: 'Join Red:',
    dd1_6: 'If the result is 2,4,6,8, you will get 195, if the result is 0, you will get 142.5.',
    dd1_7: 'Join Violet:',
    dd1_8: 'If the result is 0,5, you will get 427.5.',
    dd1_9: 'Chose Number:',
    dd1_10: 'If the result is the number of your choice,you will get 855.',

    dt2: 'Prediction',
    dd2_1: 'A round of 90 seconds, 80 seconds to place an order, 10 seconds to announce the result, 24 hours non-stop,a total of 960 rounds a day.',
    dd2_2: 'Input 100  in a round',
    dd2_3: 'Join Green:',
    dd2_4: 'If the result is 1,3,7,9, you will get 195 , if the result is 5, you will get 142.5 .',
    dd2_5: 'Join Red:',
    dd2_6: 'If the result is 2,4,6,8, you will get 195 , if the result is 0, you will get 142.5 .',
    dd2_7: 'Join Violet:',
    dd2_8: 'If the result is 0,5, you will get 427.5 .',
    dd2_9: 'Chose Number:',
    dd2_10: 'If the result is the number of your choice,you will get 855 .',
  
    dt3: 'Truth is the real fair, reliable must require transparency',
    dd3_1: 'In order to ensure the interests of each AFRSTAR investment user, in order to comply with the IBAS and MGA certification standards, the following instructions are given for the generation of parity number results.',
    dd3_2: '1. Result source: Take the last digit of the closing price of Binance BTC/USDT spot trading pair at the moment when the Parity choose time ends.',
    dd3_3: '2. Data verification: You can view the time of historical results and corresponding price from the order page, and compare the second-level historical data of Binance.',
    dd3_4: "Note: Binance is currently the world's largest cryptocurrency exchange.BTC/USDT refers to the trading price of Bitcoin/Tether. With a trading volume of $760k/day, nobody can manipulate price figures in seconds.",
    btn: 'I got it',
  },
  gameRulesMine:{
    h1: 'Mine Rule',
    dt1: 'Three ways to play: 2x2, 4x4, 6x6, each way has different number of boxes and bonuses.',
    dt2: 'Take 4x4 as an example, total of 16 boxes, one of which is buried with a mine, you need to find boxes without a mine, and you will get a bonus every time you find one',
    dt3: 'Click Start, select the input amount, and start playing.',    
    dt4: 'If the mines are not avoided, game over',
  },

  privacyPolicy:{
    p1:'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    p2:'Interpretation and Definitions',
    p3:'Interpretation',
    p4:'The words of which the initial letter is capitalized have meanings defined under the following conditions.',
    p5:'The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
    p6:'Definitions',
    p7:'For the purposes of this Privacy Policy:',
    p8:'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
    p9:'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Coem Shop.',
    p10:'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
    p11:'Account means a unique account created for You to access our Service or parts of our Service.',
    p12:'Website refers to Coem Shop, accessible from https://afrstar.com',
    p13:'Service refers to the Website.',
    p14:'Country refers to: Abuja, Nigeria',
    p15:'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
    p16:'Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.',
    p17:'Personal Data is any information that relates to an identified or identifiable individual.',
    p18:'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
    p19:'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
    p20:'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
    p21:'Collecting and Using Your Personal Data',
    p22:'Types of Data Collected',
    p23:'Personal Data',
    p24:'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
    p25:'Email address',
    p26:'First name and last name',
    p27:'Phone number',
    p28:'Address, State, Province, ZIP/Postal code, City',
    p29:'Usage Data',
    p30:'Usage Data',
    p31:'Usage Data is collected automatically when using the Service.',
    p32:"Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
    p33:'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
    p34:'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
    p35:'Tracking Technologies and Cookies',
    p36:'We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.',
    p37:'You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.',
    p38:'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.',
    p39:'We use both session and persistent Cookies for the purposes set out below:',
    p40:'Necessary / Essential Cookies',
    p41:'Type: Session Cookies',
    p42:'Administered by: Us',
    p43:'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
    p44:'Cookies Policy / Notice Acceptance Cookies',
    p45:'Type: Persistent Cookies',
    p46:'Administered by: Us',
    p47:'Purpose: These Cookies identify if users have accepted the use of cookies on the Website.',
    p48:'Functionality Cookies',
    p49:'Type: Persistent Cookies',
    p50:'Administered by: Us',
    p51:'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
    p52:'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.',
    p53:'Use of Your Personal Data',
    p54:'The Company may use Personal Data for the following purposes:',
    p55:'To provide and maintain our Service, including to monitor the usage of our Service.',
    p56:'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
    p57:'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
    p58:"To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    p59:'To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
    p60:'To manage Your requests: To attend and manage Your requests to Us.',
    p61:'We may share your personal information in the following situations:',
    p62:'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
    p63:'For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.',
    p64:'With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
    p65:'With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.',
    p66:'With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see You name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.', 
    p67:'Retention of Your Personal Data',
    p68:'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
    p69:'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
    p70:'Transfer of Your Personal Data',
    p71:"Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
    p72:'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
    p73:'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
    p74:'Disclosure of Your Personal Data',
    p75:'Business Transactions',
    p76:'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
    p77:'Law enforcement',
    p78:'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
    p79:'Other legal requirements',
    p80:'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
    p81:'Comply with a legal obligation',
    p82:'Protect and defend the rights or property of the Company',
    p83:'Prevent or investigate possible wrongdoing in connection with the Service',
    p84:'Protect the personal safety of Users of the Service or the public',
    p85:'Protect against legal liability',
    p86:'Security of Your Personal Data',
    p87:'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
    p88:"Children's Privacy",
    p89:'Our Service does not address anyone under the age of 16. We do not knowingly collect personally identifiable information from anyone under the age of 16. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.',
    p90:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
    p91:'Links to Other Websites',
    p92:"Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
    p93:'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
    p94:'Changes to this Privacy Policy',
    p95:'We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
    p96:'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
    p97:'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page',
    p98:'Other',
    p99:"If the loss is caused by the user's wrong input of bank account information, the user shall be responsible for the loss.",
  },
}
