import {createRouter, createWebHistory} from 'vue-router';

import myHome from "../views/myHome";
import taskReward from "../views/taskReward";
import agentPromotion from "../views/agentPromotion";
import crash from "../views/crash";
import myPlayList from "../views/myPlayList";
import myPlayListParity from "../views/myPlayListParity";
import myPlayListMine from "../views/myPlayListMine";
import myPlayListWheel from "../views/myPlayListWheel";
import myPlayListAndar from "../views/myPlayListAndar";
import gameHistoryList from "../views/gameHistoryList";
import userLogin from "../views/userLogin";
import userSMSLogin from "../views/userSMSLogin";
import forgotPassword from "../views/forgotPassword";
import userRegister from "../views/userRegister";
import receiveBonus from "../views/receiveBonus";
import shareBonus from "../views/shareBonus";
import bankCard from "../views/bankCard";
import addBankCard from "../views/addBankCard";
import complaintsSuggestions from "../views/complaintsSuggestions";
import complaintsSuggestions2 from "../views/complaintsSuggestions2";
import addComplaints from "../views/addComplaints";
import withdrawalBalance from "../views/withdrawalBalance";
import withdrawalHistoryList from "../views/withdrawalHistoryList";
import rechargeBalance from "../views/rechargeBalance";
import rechargeHistoryList from "../views/rechargeHistoryList";
import rechargeComplain from "../views/rechargeComplain";
import rechargeUSDT from "../views/rechargeUSDT";
import rechargeUsdtList from "../views/rechargeUsdtList";
import resetPassword from "../views/resetPassword";
import transactionsHistoryList from "../views/transactionsHistoryList";
import cashHistoryList from "../views/cashHistoryList";
import envelopesCenter from "../views/envelopesCenter";
import envelopesDetail from "../views/envelopesDetail";
import agentGrowth from "../views/agentGrowth";
import setAvatar from "../views/setAvatar";
import parityHome from "@/views/parityHome";
import gameLobby from "@/views/gameLobby";
import mineHome from "@/views/mineSweeper";
import cashTransfer from "@/views/cashTransfer";
import wheelHome from "@/views/wheelHome";
import parityDemo from "@/views/parityDemo";
import wheelDemo from "@/views/wheelDemo";
import mineDemo from "@/views/mineDemo";
import firstRechargeBonus from "@/views/firstRechargeBonus";
import inviteCoupon from "@/views/inviteCoupon";
import andarHome from "@/views/andarHome";
import rechargeC2C from "@/views/rechargeC2C";
import setCpf from "@/views/setCpf";


import testGame from "@/views/testGame";
import gameDemo from "@/views/gameDemo";

import helpSupport from "@/views/helpSupport";
import ticket2Detail from "@/views/ticket2Detail";


const routes = [
    {
        path: '/',
        name: 'index',
        component: gameLobby,
    },
    {
        path: '/lobby',
        name: 'gameLobby',
        component: gameLobby,
        meta: {
            // istoken:true,
            tx: 1
        }
    },
    {
        path: '/gameDemo',
        name: 'gameDemo',
        component: gameDemo,
        meta: {
            tx: 1
        }
    },
    {
        path: '/rechargeC2C',
        name: 'rechargeC2C',
        component: rechargeC2C,
        meta: {
            tx: 1
        }
    },
    {
        path: '/setCpf',
        name: 'setCpf',
        component: setCpf,
        meta: {
            tx: 2
        }
    },
    {
        path: '/agentPromotion',
        name: 'agentPromotion',
        component: agentPromotion,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/crash',
        name: 'crash',
        component: crash,
        meta: {
            tx: 1
        }
    },
    {
        path: '/myPlayList',
        name: 'myPlayList',
        component: myPlayList,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListParity',
        name: 'myPlayListParity',
        component: myPlayListParity,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListMine',
        name: 'myPlayListMine',
        component: myPlayListMine,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListAndar',
        name: 'myPlayListAndar',
        component: myPlayListAndar,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListWheel',
        name: 'myPlayListWheel',
        component: myPlayListWheel,
        meta: {
            tx: 2
        }
    },
    {
        path: '/gameHistoryList',
        name: 'gameHistoryList',
        component: gameHistoryList,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myHome',
        name: 'myHome',
        component: myHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/taskReward',
        name: 'taskReward',
        component: taskReward,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeBalance',
        name: 'rechargeBalance',
        component: rechargeBalance,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeUSDT',
        name: 'rechargeUSDT',
        component: rechargeUSDT,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeUsdtList',
        name: 'rechargeUsdtList',
        component: rechargeUsdtList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeHistoryList',
        name: 'rechargeHistoryList',
        component: rechargeHistoryList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/withdrawalBalance',
        name: 'withdrawalBalance',
        component: withdrawalBalance,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/withdrawalHistoryList',
        name: 'withdrawalHistoryList',
        component: withdrawalHistoryList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeComplain',
        name: 'rechargeComplain',
        component: rechargeComplain,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/cashTransfer',
        name: 'cashTransfer',
        component: cashTransfer,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/userLogin',
        name: 'userLogin',
        component: userLogin,
    },
    {
        path: '/userSMSLogin',
        name: 'userSMSLogin',
        component: userSMSLogin,
        meta: {
            tx: 2
        }
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: forgotPassword,
        meta: {
            tx: 2
        }
    },
    {
        path: '/userRegister',
        name: 'userRegister',
        component: userRegister,
        meta: {
            tx: 2
        }
    },
    {
        path: '/receiveBonus',
        name: 'receiveBonus',
        component: receiveBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/shareBonus',
        name: 'shareBonus',
        component: shareBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/bankCard',
        name: 'bankCard',
        component: bankCard,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/addBankCard',
        name: 'addBankCard',
        component: addBankCard,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/complaintsSuggestions',
        name: 'complaintsSuggestions',
        component: complaintsSuggestions,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/complaintsSuggestions2',
        name: 'complaintsSuggestions2',
        component: complaintsSuggestions2,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/addComplaints',
        name: 'addComplaints',
        component: addComplaints,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: resetPassword,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/transactionsHistoryList',
        name: 'transactionsHistoryList',
        component: transactionsHistoryList,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/cashHistoryList',
        name: 'cashHistoryList',
        component: cashHistoryList,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/envelopesCenter',
        name: 'envelopesCenter',
        component: envelopesCenter,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/envelopesDetail',
        name: 'envelopesDetail',
        component: envelopesDetail,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/setAvatar',
        name: 'setAvatar',
        component: setAvatar,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/agentrgGrowth',
        name: 'agentGrowth',
        component: agentGrowth,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/parity',
        name: 'parityHome',
        component: parityHome,
        meta: {
            tx: 1
        }
    },
    {
        path: '/parityDemo',
        name: 'parityDemo',
        component: parityDemo,
        meta: {
            tx: 1
        }
    },
    {
        path: '/mine',
        name: 'mineHome',
        component: mineHome,
        meta: {
            tx: 1
        }
    },
    {
        path: '/mineDemo',
        name: 'mineDemo',
        component: mineDemo,
        meta: {
            tx: 1
        }
    },
    {
        path: '/wheel',
        name: 'wheelHome',
        component: wheelHome,
        meta: {
            tx: 1
        }
    },
    {
        path: '/wheelDemo',
        name: 'wheelDemo',
        component: wheelDemo,
        meta: {
            tx: 1
        }
    },
    {
        path: '/testGame',
        name: 'testGame',
        component: testGame,
        meta: {
            tx: 1
        }
    },

    {
        path: '/firstRechargeBonus',
        name: 'firstRechargeBonus',
        component: firstRechargeBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/inviteCoupon',
        name: 'inviteCoupon',
        component: inviteCoupon,
        meta: {
            tx: 1
        }
    },
    {
        path: '/andar',
        name: 'andarHome',
        component: andarHome,
        meta: {
            tx: 1
        }
    },
    {
        path: '/helpSupport',
        name: 'helpSupport',
        component: helpSupport,
        meta: {
            tx: 2
        }
    },
    {
        path: '/ticket2Detail',
        name: 'ticket2Detail',
        component: ticket2Detail,
        meta: {
            tx: 2
        }
    },

    
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    //document.title = to.meta.title
    if (!localStorage.getItem('token') && to.meta.istoken === true) {
        router.push('./userRegister')
        return
    }
    next()
})

export default router;

