//import { createI18n } from 'vue-i18n'
import {isBlank, isNotBlank} from "@/assets/js/commonUtil";

/**
 * 是否debug模式
 * @returns {string}
 */
export function getDebugMode() {
    const debugMode = document.querySelector('body').getAttribute('dm');
    if (debugMode === 'DEBUG_MODE') {
        return 'true';
    }
    return debugMode;
}
/**
 * 获取国家
 *
 * 印度    in
 * 菲律宾  ph
 * 巴西    br
 * 尼日利亚 ng
 * 墨西哥   mx
 * @returns {string}
 */
export function getCountry() {
    const country = document.querySelector('body').getAttribute('c');
    if (country === 'COUNTRY') {
        return 'in';
    }
    return country;
}

/**
 * 设置页面标题
 */
export function setAppTitle() {
    switch (getCountry()) {
        case 'br':
            if(document.querySelector('body').getAttribute('s') === 's1'){
                document.title = 'BB177'
            }else if(document.querySelector('body').getAttribute('s') === 's2'){
                document.title = 'BB177'
            }
            break;

    }
}
/**
 * 设置皮肤模版
 */
 export function setSkinTemplate() {
    let country = document.querySelector('body').getAttribute('c');
    if (country === 'COUNTRY') country = 'in'

    let template = document.querySelector('body').getAttribute('s');
    if (template === 'TEMPLATE') template = 's2'
    
    import("@/assets/css/" + country + "_" + template +".css");
}

/**
 * 获取游戏iframe地址
 * @returns {string}
 */
export function getGameUrl() {
    return  document.querySelector('body').getAttribute('gu');
}

/**
 * 获取游戏Api地址
 * @returns {string}
 */
export function getGameApiUrl() {
    return  document.querySelector('body').getAttribute('gau');
}

/**
 * 获取游戏Websocket地址
 * @returns {string}
 */
export function getGameWebsocketUrl() {
    return  document.querySelector('body').getAttribute('gwu');
}
/**
 * 获取默认语言
 *
 * 印度   in_EN 
 * 菲律宾  ph_EN
 * 巴西   br_PT
 * 尼日利亚  ng_EN
 * 墨西哥 mx_ES
 * @returns {string}
 */
export function getDefaultLanguage() {
    if(localStorage.getItem("SET_LANG")){
        return localStorage.getItem("SET_LANG")
    }else{
        const country = getCountry();
        const jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
        if (country === 'in') {
            if(jsSrc.indexOf('hi') != -1){
                localStorage.setItem("SET_LANG",'in_HI');
                localStorage.setItem("ACCEPT_LANGUAGE",'hi');
                localStorage.setItem("countryImgUrl",'in/HI/');
                return 'in_HI'
            }else{ // else 'en'
                localStorage.setItem("SET_LANG",'in_EN');
                localStorage.setItem("ACCEPT_LANGUAGE",'en');
                localStorage.setItem("countryImgUrl",'in/EN/');
                return 'in_EN'
            }
        }else if(country === 'br'){
            localStorage.setItem("SET_LANG",'br_PT');
            localStorage.setItem("ACCEPT_LANGUAGE",'pt');
            localStorage.setItem("countryImgUrl",'br/PT/');
            return 'br_PT'
        }else{
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            return 'in_EN'
        }
    }
}
/**
 * 获取语言选项
 *
 * 印度   English , हिन्दी
 * 菲律宾  English
 * 巴西   Português
 * 尼日利亚  English
 * 墨西哥  Español
 * @returns {string}
 */
 export function getLanguage() {
    const country = getCountry();
    if (country === 'in') {
        return [{name: 'English'},{name: 'हिन्दी'}]
    }else if(country === 'ph' || country === 'ng'){
        return [{name: 'English'}]
    }else if(country === 'br'){
        return [{name: 'Português'}]
    }else if(country === 'mx'){
        return [{name: 'Español'},{name: 'English'}]
    }else if(country === 'vn'){
        return [{name: 'Tiếng Việt'}]
    }else{
        return []
    }
}
/**
 * 获取当前语言文本 
 * @returns {string}
 */
 export function getLanguageText(language) { //TODO
    switch(language){
        case 'in_EN':
            return 'English'
        case 'in_HI':
            return 'हिन्दी'
        case 'ph_EN':
            return 'English'
        case 'br_PT':
            return 'Português'
        case 'ng_EN':
            return 'English'
        case 'mx_EN':
            return 'English'
        case 'mx_ES':
            return 'Español'
        case 'vn_VI':
            return 'Tiếng Việt'
    }
}

/**
 * 设置语言
 * 英语    'English'
 * 印地语   'हिन्दी'
 * 葡萄牙语  'Português'
 * 墨西哥    'Español'
 * @returns {string}
 */
 export function setLanguage(country, language) {
    switch(language){
        case 'English':
            localStorage.setItem("SET_LANG",country + '_EN');
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            break;
        case 'हिन्दी':
            localStorage.setItem("SET_LANG",country + '_HI');
            localStorage.setItem("ACCEPT_LANGUAGE",'hi');
            break;
        case 'Português':
            localStorage.setItem("SET_LANG",country + '_PT');
            localStorage.setItem("ACCEPT_LANGUAGE",'pt');
            break;
        case 'Español':
            localStorage.setItem("SET_LANG",country + '_ES');
            localStorage.setItem("ACCEPT_LANGUAGE",'es');
            break;
        case 'Tiếng Việt':
            localStorage.setItem("SET_LANG",country + '_VI');
            localStorage.setItem("ACCEPT_LANGUAGE",'vi');
            break;
        default:
            break; 
    }
}
/**
 * 设置 ACCEPT_LANGUAGE
 * 印度    'en', 'hi'
 * @returns {string}
 */
 export function setAcceptLanguage() {
    if(!localStorage.getItem('ACCEPT_LANGUAGE')){
        const jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
        const country = getCountry();
        if (country === 'in') {
            if(jsSrc.indexOf('en') != -1){
                localStorage.setItem("SET_LANG",'in_EN');
                localStorage.setItem("ACCEPT_LANGUAGE",'en');
                localStorage.setItem("countryImgUrl",'in/EN/');
            }else if(jsSrc.indexOf('hi') != -1){
                localStorage.setItem("SET_LANG",'in_HI');
                localStorage.setItem("ACCEPT_LANGUAGE",'hi');
                localStorage.setItem("countryImgUrl",'in/EN/');
            }else{
                localStorage.setItem("SET_LANG",'in_EN');
                localStorage.setItem("ACCEPT_LANGUAGE",'en');
                localStorage.setItem("countryImgUrl",'in/EN/');
            }
        }else{
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            return
        }
    }
}
/**
 * 设置图片路径
 * @returns {string}
 */
 export function setImgUrl() {
    const currentLanguage = getDefaultLanguage()
    const template = document.querySelector('body').getAttribute('s');
    switch (currentLanguage) {
        case "in_EN":
            if(template === 's1'){
                localStorage.setItem("countryImgUrl","in/EN/S1/");
            }else if(template === 's2'){
                localStorage.setItem("countryImgUrl","in/EN/S2/");
            }
            break;
        case "in_HI":
            if(template === 's1'){
                localStorage.setItem("countryImgUrl","in/EN/S1/");
            }else if(template === 's2'){
                localStorage.setItem("countryImgUrl","in/EN/S2/");
            }
            break;
        default:
            break;
    }
}
/**
 * 获取最后一次游戏页面名称
 * 如果参数是 'recharge' 充值  否则返回推广中的游戏  默认返回 'crash'  
 */
 export function getLastTimeGameName(t) {
    if(localStorage.getItem('myLastTimeGame')){
        //return localStorage.getItem('myLastTimeGame')
        return 'crash'
    }else if(t === 'recharge'){
        return 'recharge'
    }else{
        //const commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
        //return commonInfo.promoteGame
        return 'crash'
    }   
}
/**
 * 设置最后一次游戏页面名称
 */
 export function setLastTimeGameName(name) {
    if(name === 'lobby' && isNotBlank(localStorage.getItem('myLastTimeGame'))){
        return
    }else{
        localStorage.setItem("myLastTimeGame", name);
    }
    if(isBlank(localStorage.getItem('firstVisit'))) {
      localStorage.setItem("firstVisit", name)
    }  
}
/**
 * 限制试玩次数初始化
 */
export function demoRestrictionsDate() {
    let t = new Date();
    let d = t.getDate()
    if(d !== parseInt(localStorage.getItem('demoTimes'))){
        localStorage.setItem('demoTimes', d);
        localStorage.setItem('parityDemoNumber', 0);
        localStorage.setItem('mineDemoNumber', 0);
        localStorage.setItem('wheelDemoNumber', 0);
    }
}
/**
 * 限制试玩次数
 */
export function demoRestrictions(gameName) {
    let name = gameName + 'DemoNumber'
    let times = parseInt(localStorage.getItem(name)) + 1
    if(times > 10){
        return times
    }else{
        localStorage.setItem(name, times);
        return times
    }
}

/**
 * googlePlay top下载
 */
// export function showGooglePlayDownload(dom) {
//     let id = '#'+ dom
//     let element = ''
//     if(document.querySelector(id) !== null){
//         element = document.querySelector(id).style.display= 'block'; 
//         element.style.display= 'block'; 
//     }else{
//         return
//     }
//     return element
// }

// export function toGooglePlay(dom, url){
//     if(url !== null && url !== undefined){
//       document.querySelector('#googlePlayBtn').setAttribute("href", url)
//     }
//     if(dom !== null && dom !== undefined){
//       let id = '#'+ dom
//       let element = ''
//       if(document.querySelector(id) !== null){
//         element = document.querySelector(id);
//         element.style.display= 'block'; 
//         document.getElementById('downloadDialogPopup').style.display= 'block';
//       }
//     }
//   }


/**
 * 初始化设置
 */
export function init() {
    //设置页面标题
    setAppTitle()
    //设置图片路径
    setImgUrl()
    //设置 ACCEPT_LANGUAGE
    setAcceptLanguage()
    //设置皮肤模版
    setSkinTemplate()
}
